import { useState, useMemo, useEffect } from "react";


export function useYearFilterForFolders(data) {

    const [selectedYear, setSelectedYear] = useState("All");
    const [years, setYears] = useState(["All"]);

    useEffect(() => {
        if (data && data.length > 0) {
            const uniqueYears = new Set();
            data.forEach(folder => {
                folder.filesPerYear.forEach(file => {
                    uniqueYears.add(file.year);
                });
            });
            const sortedYears = ["All", ...Array.from(uniqueYears).sort((a, b) => b - a)];
            setYears(sortedYears);
            // Set default selected year if needed
            const currentYearMinusOne = new Date().getFullYear() - 1;
            const defaultYear = sortedYears.includes(currentYearMinusOne.toString()) ? currentYearMinusOne : sortedYears[1] || 'All';
            setSelectedYear(defaultYear.toString());
        }
    }, [data]);

    const handleSelectChange = (e) => {
        setSelectedYear(e.target.value);
    };

    const filteredData = useMemo(() => {
        if (!data || data.length === 0) return [];
        if (selectedYear === 'All') {
            return data.map(folder => ({
                ...folder,
                totalFiles: folder.filesPerYear.reduce((acc, fy) => acc + fy.count, 0)
            }));
        }
        return data.map(folder => {
            const yearData = folder.filesPerYear.find(fy => fy.year === selectedYear);
            return {
                ...folder,
                totalFiles: yearData ? yearData.count : 0
            };
        });
    }, [selectedYear, data]);
    
    return {
        years,
        selectedYear,
        handleSelectChange,
        filteredData,
    };
}