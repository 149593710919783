import React, { useEffect, useState } from 'react';
import { useParams, useLocation, Link } from 'react-router-dom';
import { useSelector } from "react-redux";
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import Tooltip from '@mui/material/Tooltip';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import CreateNewFolderIcon from '@mui/icons-material/CreateNewFolder';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import IconButton from '@mui/material/IconButton';
import { capitalize } from '@mui/material';

import {useYearFilterForFolders } from '../../../../hooks/useYearFilterForFolders'
import { useFetchData } from '../../../../hooks/useFetchData';

import LoadingGrid from '../../../ui/Loading';
import AddNewFiles from '../GeneralDoc/AddNewFiles';
import AddPersonalFolders from '../GeneralDoc/AddPersonalFolders';
import ErrorDataComponent from '../../../ui/ErrorDataComponent';
import EditFolder from '../../Folders/EditFolder';
import DeleteFolder from '../GeneralDoc/DeleteFolder';

import FolderIcon from '../../../../assets/icons/folder-3.png';

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

const BusinessDocumentsSummary = () => {

    const { userToken } = useSelector((state) => state.user);
    const { clientId } = useParams();
    let query = useQuery();
    let businessId = query.get("businessId");
    const { clientDetails } = useSelector((state) => state.adminClient);
    const [childChanges, setChildChanges] = useState(false);
    const [open, setOpen] = useState(false);
    const [isNewFoldersOpen, setNewFoldersOpen] = useState(false);
    const [folders, setFolders] = useState([])
    const [isUploadButtonVisible, setUploadButtonVisible] = useState(true);
    const [anchorEl, setAnchorEl] = useState(null);
    const [selectedFolder, setSelectedFolder] = useState(null);
    const [isEditOpen, setEditOpen] = useState(false);
    const [isDeleteOpen, setDeleteOpen] = useState(false);
    const [menuItem, setMenuItem] = useState(null);

    
    const [loading, data, error] = useFetchData(`/api/client-page/business/documents-summary/${clientId}/${businessId}`, userToken, childChanges);

    const {
        years,
        selectedYear,
        handleSelectChange,
        filteredData,
    } = useYearFilterForFolders(data?.folderSummary);

    const handleAddDocs = () => {
        setOpen(true)
    }

    const handleAddFolders = () => {
        setNewFoldersOpen(true)
    }

    const handleEditOpen = () => {
        setEditOpen(true);
    }

    const handleDeleteOpen = () => {
        setDeleteOpen(true);
    }

    const handleMenuOpen = (event, folder) => {
        setAnchorEl(event.currentTarget);
        setMenuItem(folder);
        setSelectedFolder({
            name: folder.name,
            _id: folder.folderId,
            access: folder.access,
            folderType: folder.folderType
        });
    };
      
    const handleMenuClose = () => {
        setAnchorEl(null);
        setSelectedFolder(null);
        setMenuItem(null);
    };

    useEffect(() => {
        if(data && data.folderSummary) {
            const transformedFolders = data.folderSummary.map(item => ({
                folderName: item.name,
                folderId: item.folderId
            }));

            setFolders(transformedFolders);
        }
    }, [data])

    useEffect(() => {
        if(data && data.folderSummary) {
            setUploadButtonVisible(data.isDocumentUploadActive)
        }
    }, [data]);

    if(loading) {
        return <LoadingGrid gridNumber={4} />
    }

    if(error) {
        return (
            <>
                <Grid container id='folders-header' alignItems="center" justifyContent="space-between">
                    <Grid item xs={6}>
                        <Typography component="h1">Business Documents</Typography>
                    </Grid>
                </Grid>
                <ErrorDataComponent msg={error?.data?.error || 'Something went wrong. Please reload the page or contact support if the issue persists.'} />
            </>
        )
    }

  

    return (
        <>
            <Grid container id='folders-header' alignItems="center" justifyContent="space-between">
                <Grid item xs={6}>
                    {data && data.businessName ? (
                        <Typography component="h1">
                            {data.businessName} Documents
                        </Typography>

                    ) : (
                        <Typography component="h1">
                            Business Documents
                        </Typography>
                    )}
                </Grid>
                <Grid item>
                    <FormControl sx={{width: '150px'}}>
                        <InputLabel id="year-select-label">Year</InputLabel>
                        <Select
                            labelId="year-select-label"
                            id="year-select"
                            size="small" 
                            value={selectedYear}
                            label="Year"
                            onChange={handleSelectChange}
                        >
                            {years.map(year => (
                                <MenuItem key={year} value={year}>
                                    {year}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
            </Grid>
            {isUploadButtonVisible ? (
                <Grid className='folders-btn-container' container alignItems="center" justifyContent="start" gap={2} mb={6}>
                    <Grid item>
                        <Button 
                            onClick={handleAddFolders}
                            startIcon={<CreateNewFolderIcon />}
                        >
                            Create Folder
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button 
                            onClick={handleAddDocs}
                            startIcon={<CloudUploadIcon />}
                        >
                            Upload Files
                        </Button>
                    </Grid>
                </Grid>
            ) : (
                <Grid item className='inactive-account-msg' container alignItems="center" justifyContent="end">
                    <Typography component="span">
                        Inactive account
                    </Typography>
                    <Tooltip title="Documents cannot be uploaded here because the client has only permissions to view documents">
                        <HelpOutlineIcon />
                    </Tooltip>
                </Grid>
            )}
            <Box id="admin-folders-container">
                <Grid container justifyContent="center" spacing={3} style={{ justifyContent: 'flex-start' }}>
                    {filteredData.map((item, index) => {
                        const isDisabled = Array.isArray(menuItem?.filesPerYear) && menuItem.filesPerYear.length > 0;
                        return (
                            <Grid container justifyContent="center" item key={index} xs={12} sm={6} md={4} lg={3}>
                                <Grid item>
                                    <Paper className="folder-box">
                                        {item.folderType === 'business-specific' && (
                                            <Box className="folder-settings-btn">
                                                <IconButton onClick={(e) => handleMenuOpen(e, item)}>
                                                    <MoreHorizIcon fontSize="small" />
                                                </IconButton>
                                                <Menu className='folder-menu' anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleMenuClose}>
                                                    <MenuItem onClick={() => { handleEditOpen(); handleMenuClose(); }}>
                                                        <EditIcon fontSize="small" className="menu-icon" />
                                                        <span className="menu-text">Edit</span>
                                                    </MenuItem>
                                                    <MenuItem
                                                        onClick={() => { handleDeleteOpen(); handleMenuClose(); }} 
                                                        disabled={isDisabled}
                                                    >
                                                        <DeleteIcon fontSize="small" className="menu-icon" />
                                                        <span className="menu-text">Delete</span>
                                                    </MenuItem>
                                                </Menu>
                                            </Box>
                                        )}
                                
                                        <Link
                                            to={`/admin/client-page/business/documents/${clientId}/${businessId}/folder/${item.name}/year/${selectedYear}`} 
                                            style={{ textDecoration: 'none' }}
                                        >
                                            <Box className="folder-content">
                                                <img src={FolderIcon} alt={item.name} />
                                                <Typography variant="subtitle1" align="center">
                                                    {capitalize(item.name)} ({item.totalFiles}) 
                                                </Typography>
                                                <Typography className="folder-access">{item.access}</Typography>
                                            </Box>
                                        </Link>
                                    </Paper>
                                </Grid>
                            </Grid>
                        )
                    })}
                </Grid>
            </Box>
            <AddPersonalFolders 
                open={isNewFoldersOpen}
                setOpen={setNewFoldersOpen}
                userType="business"
                businessId={businessId}
                setChildChanges={setChildChanges}
                
            />
            <AddNewFiles 
                open={open}
                setOpen={setOpen}
                userType="business"
                folders={folders}
                setChildChanges={setChildChanges}
                businessId={businessId}
            />
            <EditFolder 
                open={isEditOpen}
                currentFolder={selectedFolder}
                setEditOpen={setEditOpen}
                userToken={userToken}
                setChildChanges={setChildChanges}
            />
            <DeleteFolder 
                open={isDeleteOpen}
                selectedFolder={selectedFolder}
                userToken={userToken}
                setChildChanges={setChildChanges}
                setOpen={setDeleteOpen}
            />
        </>

    )
}

export default BusinessDocumentsSummary;