import React from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import ErrorIcon from '../../assets/icons/error-icon.png'


const ErrorDataComponent = ({ msg }) => {

    return (
        <Box id="error-data-box">
            <Grid container justifyContent="center">
                <Grid item>
                    <img src={ErrorIcon} />
                </Grid>
            </Grid>
            <Grid container justifyContent="center" mt={2}>
                <Grid item>
                    <Typography component="h2">{msg}</Typography>
                </Grid>
            </Grid>
        </Box>
    )
}

export default ErrorDataComponent;