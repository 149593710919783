import React, { useEffect, useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import axios from 'axios';
import Dialog from '@mui/material/Dialog';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import CircularProgress from '@mui/material/CircularProgress';
import Alert from '@mui/material/Alert';
import ListSubheader from '@mui/material/ListSubheader';

import DialogSuccessMsg from '../../../ui/DialogSuccessMsg';

import { capitalize } from '@mui/material';

const EditMultipleFiles = ({ open, setOpen, businesses, folders, setChildChanges, selectedFolder, selectedFiles, businessId, setSelectedFiles, currentFolder, userId, selectedYear }) => {

    const { userToken } = useSelector((state) => state.user);
    const [state, setState] = useState({
        folder: { folderId: '', folderName: '', folderType: '' },
        year: ''
    });
    const [selectedBusiness, setSelectedBusiness] = useState(businessId ? businessId : '');
    const [isUpdated, setIsUpdated] = useState(false);
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);
    const modalRef = useRef();


    // useEffect(() => {
    //     if(state) {
    //         console.log(state)
    //     }
    // }, [state])

    // useEffect(() => {
    //     if(selectedBusiness) {
    //         console.log(selectedBusiness)
    //     }
    // }, [selectedBusiness])


	const handleClose = () => {
       
		setOpen(false);
        setIsUpdated(false);
        setError('');
        setState({
            folder: {
                folderName: currentFolder.name,
                folderId: currentFolder._id,
                folderType: currentFolder.folderType
            },
            year: selectedYear || '',
        });
        setSelectedBusiness(businessId ? businessId : '');
        if(isUpdated) {
            setChildChanges((prevChanges) => !prevChanges);
            setSelectedFiles([])
        }
	}

    useEffect(() => {
        if(currentFolder && selectedYear) {
            setState({
                folder: {
                    folderName: currentFolder.name,
                    folderId: currentFolder._id,
                    folderType: currentFolder.folderType
                },
                year: selectedYear,
                userId: userId
            });

        }
    }, [currentFolder, selectedYear]);

    useEffect(() => {
        if(selectedBusiness && state.folder.folderType === 'user-specific') {
            setState((prevState) => ({
                ...prevState,
                folder: {
                    folderName: '',
                    folderId: '',
                    folderType: ''
                }
            }));
        } else if (!selectedBusiness && state.folder.folderType === 'business-specific') {
            setState((prevState) => ({
                ...prevState,
                folder: {
                    folderName: '',
                    folderId: '',
                    folderType: ''
                }
            }));
        }
    }, [state, selectedBusiness])

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setState({ ...state, [name]: value });
    }

    // const handleFolderInputChange = (e) => {
    //     const folderId = e.target.value;
    
    //     // Find the selected folder from the folders array
    //     const selectedFolder = folders.find((folder) => folder._id === folderId);
    
    //     // Update state with folder name and id
    //     setState((prevState) => ({
    //         ...prevState,
    //         folder: {
    //             folderName: selectedFolder ? selectedFolder.name : '',
    //             folderId: selectedFolder ? selectedFolder._id : ''
    //         }
    //     }));
    // };

    const handleFolderInputChange = (e) => {
        const folderId = e.target.value;
    
    
        // Combine all folder categories into a single array
        const allFolders = [
            ...folders.general,
            ...folders.userSpecific,
            ...folders.businessSpecific
        ];
    
        // Find the selected folder from the combined folders array
        const selectedFolder = allFolders.find(
            folder => String(folder._id) === String(folderId)
        );

        console.log(selectedFolder)
    
        // Update state with folder name and id
        setState((prevState) => ({
            ...prevState,
            folder: {
                folderName: selectedFolder ? selectedFolder.name : '',
                folderId: selectedFolder ? selectedFolder._id : '',
                folderType: selectedFolder ? selectedFolder.folderType : ''
            }
        }));
    };

    const handleBusinessChange = (event) => {
        setSelectedBusiness(event.target.value);
    };


    const postData = async (e) => {
		e.preventDefault();
		setLoading(true);

        try {
            const config = {
                headers: {
                    Authorization: `Bearer ${userToken}`,
                },
            };
            const data = {
                ...state,
                businessId: selectedBusiness,
                selectedFiles: selectedFiles
            };

            // console.log(data);
            // return;
            
            const response = await axios.post(
                '/api/client-page/files-multiple/edit',
                data,
                config
            );

            if(response.data.success) {
                setIsUpdated(true);
                setError('');
                setLoading(false);
            } else {
                setError(error.response.data.error || "An unexpected error occurred while updating the file. Please try again later or contact support if the issue persists.");
            }

        } catch (error) {
            setError(error.response.data.error || "An unexpected error occurred while updating the file. Please try again later or contact support if the issue persists.");
        } finally {
            setLoading(false);
        }
	};

	return (
		<Dialog
			aria-labelledby="admin-doc-edit-modal"
        	aria-describedby="admin-doc-edit-modal"
            id="admin-doc-edit-modal"
        	open={open}
			onClose={handleClose}
            fullWidth
            maxWidth="md"
		>
			<Container sx={{ m: 0, p: 2 }} style={{ overflow: 'auto' }} ref={modalRef}>
                <Grid container justifyContent="space-between" alignItems="center" className="edit-dialog-header">
                    <Grid item>
                        <Chip color="warning" label="Edit Mode" />
                    </Grid>
					<Grid item>
						<IconButton
                            onClick={handleClose}
                        >
                            <CloseIcon />
                        </IconButton>
					</Grid>
				</Grid>
                {error && (
                    <Grid container mt={3}>
                        <Grid item xs={12}>
                            <Alert severity="error" onClose={() => setError(null)}  className='business-alert'>
                                {error}
                            </Alert>
                        </Grid>
                    </Grid>
                )}
                {isUpdated ? (
                    <Box className="admin-doc-modal">
                        <DialogSuccessMsg msg="Changes saved successfully." />
                    </Box>
                ) : (
                    <Box component="form" onSubmit={postData}>
                        <Grid container mt={5} mb={5} justifyContent="center">
                            <Grid item xs={7} mb={2}>
                                <TextField label="Year" fullWidth required value={state.year} name="year" onChange={handleInputChange} />   
                            </Grid> 
                            {folders ? (
                                // <Grid item xs={7} mb={2}>
                                //     <TextField 
                                //         select 
                                //         name="folder" 
                                //         label="Folder" 
                                //         fullWidth 
                                //         value={state.folder ? state.folder.folderId : ''}
                                //         onChange={handleFolderInputChange}
                                //     >
                                //         {folders.map((item) => (
                                //             <MenuItem value={item._id} key={item._id}>
                                //                     {capitalize(item.name)}
                                //             </MenuItem>
                                //         ))}
                                //     </TextField>
                                // </Grid> 
                                <Grid item xs={7} mb={2}>
                                    <TextField
                                        select
                                        required
                                        name="folder"
                                        label="Folder"
                                        fullWidth
                                        value={state.folder.folderId || ''}
                                        onChange={handleFolderInputChange}
                                        className='folder-select'
                                    >
                                        {folders.general.length > 0 && <ListSubheader className='folder-select-subheader'>General Folders</ListSubheader>}
                                        {folders.general.map(item => (
                                            folders.general.length > 0 && (
                                                <MenuItem key={item._id} value={item._id} className='folder-select-item'>
                                                    {capitalize(item.name)}
                                                </MenuItem>
                                            )
                                        ))}

                                        {folders.userSpecific.length > 0 && <ListSubheader className='folder-select-subheader'>Client Specific Folders</ListSubheader>}
                                        {folders.userSpecific.map(item => (
                                            folders.userSpecific.length > 0 && (
                                                <MenuItem 
                                                    key={item._id} 
                                                    value={item._id} 
                                                    className='folder-select-item'
                                                    disabled={!!selectedBusiness}
                                                >
                                                    {capitalize(item.name)}
                                                </MenuItem>
                                            )
                                        ))}

                                        {folders.businessSpecific.length > 0 && <ListSubheader className='folder-select-subheader'>Business Specific Folders</ListSubheader>}
                                        {folders.businessSpecific.map(item => (
                                            folders.businessSpecific.length > 0 && (
                                                <MenuItem 
                                                    key={item._id} 
                                                    value={item._id} 
                                                    className='folder-select-item'
                                                    disabled={!selectedBusiness}
                                                >
                                                    {capitalize(item.name)}
                                                </MenuItem>
                                            )
                                        ))}
                                    </TextField>
                                </Grid>
                            ) : null} 
                            {businesses && businesses.length > 0 ? (
                                <Grid item xs={7} mb={2}>
                                    <TextField 
                                        select 
                                        name="business" 
                                        label="Add Business" 
                                        fullWidth 
                                        value={selectedBusiness}
                                        onChange={handleBusinessChange}
                                    >
                                        <MenuItem value="">
                                            <em>None</em>
                                        </MenuItem>
                                        {businesses.map((item) => (
                                            <MenuItem value={item.businessId} key={item.businessId}>
                                                    {item.businessName}
                                                    {item.businessStatus === 2 && 
                                                        <Chip 
                                                            label="Dissolved" 
                                                            color="secondary"
                                                            size="small" 
                                                            style={{ marginLeft: '10px' }} 
                                                        />
                                                    }
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </Grid> 
                            ) : null} 
                            <Grid item xs={10} container justifyContent="flex-end">
                                <Grid item>
                                    <Button 
                                        className='submit-button' 
                                        type="submit"
                                        disabled={loading}
                                    >
                                        {loading ? (
                                            <>
                                                Save&nbsp;
                                                <CircularProgress size={18} color="inherit" />
                                            </>
                                        ) : (
                                            'Save'
                                        )}
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Box>
                )}
			</Container>
		</Dialog>
	)
};

export default EditMultipleFiles;