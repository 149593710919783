import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from "react-router-dom";
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Collapse from '@mui/material/Collapse';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import Tooltip from '@mui/material/Tooltip';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

import { useFetchData } from '../../../../hooks/useFetchData';

import MergedPersonalInfo from './MergedPersonalInfo';
import MergedBusinessInfo from './MergedBusinessInfo';
import GeneralClientNotifications from '../../../general-client-components/general-notifications';

const MergedAccountInfo = () => {

    const { mergedClientId } = useParams();
    const { userToken } = useSelector((state) => state.user);
    const [loading, data, error] = useFetchData(`/api/c/dashboard/my-merged-account/${mergedClientId}`, userToken);
    const [personalInfoOpen, setPersonalInfoOpen] = useState(true);
    const [notificationsOpen, setNotificationsOpen] = useState(true);

    const toggleSection = (section) => {
        switch (section) {
            case 'personalInfo':
                setPersonalInfoOpen(!personalInfoOpen);
                break;
            case 'notificationsTitles':
                setNotificationsOpen(!notificationsOpen);
                break;
            default:
            break;
        }
    };

    const capitalize = (str) => {
        str = str.replace(/([a-z])([A-Z])/g, '$1 $2');
        return str.charAt(0).toUpperCase() + str.slice(1);
    }

    return (
        <>
            <Box id='merged-account-info'>
                <Grid container justifyContent="space-between" alignItems="center">
                    <Grid item>
                        <Typography component="h1">
                            Merged Dashboard
                        </Typography>
                    </Grid>
                    {!!data && !data?.active && (
                        <Grid item className='inactive-account-msg' mt={1}>
                                <Typography component="span">
                                    Inactive account
                                </Typography>
                                <Tooltip title="Inactive Account: Active Merged Account. You can still use this account if you have businesses.">
                                    <HelpOutlineIcon />
                                </Tooltip>
                        </Grid>
                    )}
                </Grid>
                {!!data ? (
                    <>
                        <Box  className={!personalInfoOpen ? 'section' : 'section open'} onClick={() => toggleSection('personalInfo')} mt={5}>
                            <Box display="flex" alignItems="center" justifyContent="space-between" className="section-header">
                                <Typography component="h3">Personal Information</Typography>
                                {!personalInfoOpen ? <ExpandMoreIcon /> : <ExpandLessIcon />}
                            </Box>
                            <Collapse in={personalInfoOpen} timeout={500} unmountOnExit>
                                <MergedPersonalInfo clientInfo={data} />
                            </Collapse>
                        </Box>
                        <Box className={!notificationsOpen ? 'section' : 'section open'} onClick={() => toggleSection('notificationsTitles')}>
                            <Box display="flex" alignItems="center" justifyContent="space-between" className="section-header">
                                <Typography component="h3">Notifications</Typography>
                                {!notificationsOpen ? <ExpandMoreIcon /> : <ExpandLessIcon />}
                            </Box>
                            <Collapse in={notificationsOpen} timeout={500} unmountOnExit>
                                <GeneralClientNotifications 
                                    userId={mergedClientId}
                                    userToken={userToken} 
                                    userType="merged"
                                />
                            </Collapse>
                        </Box>
                       
                    </>
                ): null}
            </Box>
        </>
    )

}

export default MergedAccountInfo;