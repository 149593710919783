import React, { useEffect, useState, useRef } from 'react';
import { useDispatch } from 'react-redux';
import axios from 'axios';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import MenuItem from '@mui/material/MenuItem';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import Checkbox from '@mui/material/Checkbox';
import FormLabel from '@mui/material/FormLabel';
import IconButton from '@mui/material/IconButton';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import Switch from '@mui/material/Switch';
import DeleteIcon from '@mui/icons-material/Delete';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import CircularProgress from '@mui/material/CircularProgress';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import Tab from '@mui/material/Tab';

import AddBusinessUser from './AddNewBusinessUser';

import ActiveAndNewUsers from './BusinessSettingsTabs/ActiveAndNewUsers';
import FrozenUsers from './BusinessSettingsTabs/FrozenUsers';
import GeneralSettings from './BusinessSettingsTabs/GeneralSettings';
import BusinessSummary from './BusinessSettingsTabs/BusinessSummary';

import { fetchAdminClientDetails } from '../../../../actions/adminClientActions';
import { useFetchData } from '../../../../hooks/useFetchData';


const BusinessSettingsModal2 = ({ open, setOpen, userToken, view, id, businessId, updateBusinessList }) => {

    
    const [childChanges, setChildChanges] = useState(false);
    const [value, setValue] = useState('1');
    const [activeCurrentUsers, setActiveCurrentUsers] = useState([]);
    const [frozenCurrentUsers, setFrozenCurrentUsers] = useState([]);
    const [fetchParams, setFetchParams] = useState(null);
    const [business, setBusiness] = useState(null);
    const [isSubmitted, setSubmitted] = useState(false);
    

    useEffect(() => {
        if (businessId) {
            setFetchParams(`/api/admin/businesses/all/${businessId}`);
        }
    }, [businessId, open]);

    const [ loading, data, error ] = useFetchData(fetchParams, userToken, childChanges);

    useEffect(() => {
        if(business) {
            const active = business.users.filter(user => user.active === true);
            const frozen = business.users.filter(user => user.active === false);

            setActiveCurrentUsers(active);
            setFrozenCurrentUsers(frozen);
        }
    }, [business])

    useEffect(() => {
        if(data) {
            setBusiness(data)
        }
    }, [data])

    useEffect(() => {
        if(business) {
            console.log(business)
        }
    }, [business])

    const handleClose = (e) => {
        e.stopPropagation();
        if(view === 'general') {
            updateBusinessList((prevChanges) => !prevChanges);
        }
        setOpen(false);
        // setError(null);
        // setLoading(null);
        // setSubmitted(false);
        // setUpdatedBusiness(business);
        // setNewUsers([]);
    }

    const handleChange = (event, newValue) => {
        setValue(newValue);
        if(isSubmitted) {
            setChildChanges((prevChanges) => !prevChanges);
        }
        setSubmitted(false)
    };


    return (

        <Dialog
            aria-labelledby="business-settings-modal"
            aria-describedby="business-settings-modal"
            id='business-settings-modal'
            open={open}
            onClose={handleClose}
            fullWidth
            maxWidth="md"
            onClick={(e) => e.stopPropagation()}
        >
            <Container className="new-question-container" sx={{ m: 0, p: 2 }} style={{ overflow: 'auto' }}>
                <Grid container justifyContent="space-between" alignItems="center" className="add-que-header" mb={3}>
                    <Grid item>
                        <Chip color="success" label="Settings" />
                    </Grid>
                    <Grid item>
                        <IconButton onClick={handleClose}>
                            <CloseRoundedIcon />
                        </IconButton>
                    </Grid>
                </Grid>
                {business && (
                    <>
                        <Grid container className='business-name'>
                            <Grid item xs={12}>
                                <Typography component="h2">
                                    <Typography component="span">
                                        {business.businessName}
                                    </Typography>
                                        {business.status === 'active' ? 'Business Settings' : 'Business Summary'}
                                </Typography>
                            </Grid>
                        </Grid>
                        <Box>
                        {business.status === 'active' ? (
                            <TabContext value={value}>
                                <TabList
                                    onChange={handleChange}
                                    className="modal-custom-tablist"
                                >
                                   
                                    <Tab 
                                        value="1" 
                                        className='first-tab modal-custom-tab' 
                                        label="Active/New Clients"
                                    />
                                    <Tab 
                                        value="2"
                                        className='second-tab modal-custom-tab' 
                                        label="Frozen Clients"
                                    />
                                    <Tab 
                                        value="3" 
                                        className='third-tab modal-custom-tab' 
                                        label="Dissolve Business"
                                    />
                                </TabList>
                                <TabPanel value="1">
                                    <ActiveAndNewUsers 
                                        activeCurrentUsers={activeCurrentUsers} 
                                        userToken={userToken}
                                        businessId={business._id}
                                        setChildChanges={setChildChanges}
                                        view={view}
                                        id={id}
                                        isSubmitted={isSubmitted}
                                        setSubmitted={setSubmitted}
                                    />
                                </TabPanel>
                                <TabPanel value="2">
                                    <FrozenUsers 
                                        frozenCurrentUsers={frozenCurrentUsers}
                                        activeCurrentUsers={activeCurrentUsers} 
                                        businessId={business._id}
                                        userToken={userToken}
                                        setChildChanges={setChildChanges}
                                        isSubmitted={isSubmitted}
                                        setSubmitted={setSubmitted}
                                    />
                                </TabPanel>
                                <TabPanel value="3">
                                    <GeneralSettings 
                                        businessId={business._id}
                                        frozenCurrentUsers={frozenCurrentUsers}
                                        activeCurrentUsers={activeCurrentUsers} 
                                        userToken={userToken}
                                        setChildChanges={setChildChanges}
                                        isSubmitted={isSubmitted}
                                        setSubmitted={setSubmitted}
                                    />
                                </TabPanel>
                            </TabContext>
                        ) : (
                            <BusinessSummary 
                                business={business}
                            />
                        )}
                        </Box>
                    </>

                )}
                
            </Container>
            
        </Dialog>

    )
}


export default BusinessSettingsModal2;