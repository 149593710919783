import React from 'react';
import Skeleton from '@mui/material/Skeleton';
import Grid from '@mui/material/Grid';


const LoadingGrid = ({gridNumber}) => {

    return (
        <Grid container spacing={5} ml={3} mt={3} sx={{width: '90%'}}>
            {Array.from({ length: gridNumber || 3 }).map((_, index) => (
                <Grid xs={3} item key={index}>
                    <Skeleton variant="rounded"height={200} />
                </Grid>
            ))}
        </Grid>
    )
}

export default LoadingGrid;