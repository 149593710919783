import React, { useEffect, useState, useMemo } from 'react';
import { useSelector } from "react-redux";
import { useParams} from 'react-router-dom';
import axios from 'axios';
import download from 'downloadjs';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import List from "@mui/material/List";
import ListItem from '@mui/material/ListItem';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import Button from '@mui/material/Button';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import Alert from '@mui/material/Alert';

import { fileIcons, getFileExtension } from '../../../utils/fileIcons.js'

import ErrorDataComponent from '../../ui/ErrorDataComponent.js';
import PaginationControlled from '../../ui/Pagination';
import LoadingList from '../../ui/LoadingList.js';

import { useFetchData } from '../../../hooks/useFetchData.js';


const GeneralClientFiles = ({ endpoint, backLink, title = 'All Documents' }) => {

    const { userToken } = useSelector((state) => state.user);
    const { folderName, year } = useParams();

    // Calculate the default year (current year - 1)
    const defaultYear = year ? year : (new Date().getFullYear() - 1).toString();
    const [selectedYear, setSelectedYear] = useState(defaultYear);
    const [files, setFiles] = useState([]);
    const [years, setYears] = useState([defaultYear]);
    const [downloadError, setDownloadError] = useState(null);

    const [loading, data, error] = useFetchData(`${endpoint}/${folderName}/${selectedYear}`, userToken);


    const [currentPage, setCurrentPage] = useState(1);
    const [recordsPerPage] = useState(15);


    // Pagination logic
    const indexOfLastRecord = currentPage * recordsPerPage;
    const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
    const currentRecords = files.slice(indexOfFirstRecord, indexOfLastRecord);
    const nPages = Math.ceil(files.length / recordsPerPage);

    useEffect(() => {
        if (data) {
            setFiles(data.files);
            setYears(prevYears => Array.from(new Set([...prevYears, ...data.years])));
        }
    }, [data]);

    const handleSelectChange = (e) => {
        setSelectedYear(e.target.value);
        setCurrentPage(1); 
    };

    const downloadFile = async (id, filename, mimetype) => {
        const config = {
            responseType: 'blob',
            headers: {
                Authorization: `Bearer ${userToken}`,
            },
        };
        
        try {
            const response = await axios.get(`/api/c/dashboard/file/download/${id}`, config);
            const file = new Blob([response.data], { type: mimetype });
      
            if (file.size === 0) {
                setDownloadError('The file could not be downloaded because it is empty. Please contact support for assistance.');
                return;
            }

            const realFileName = filename;
      
            download(file, realFileName, mimetype);
        } catch (error) {
            console.log(error)

            if (error.response && error.response.status === 400) {
                setDownloadError('An error occurred while downloading the file. Please try again later or contact support if the issue persists.');
                return;
            }
            setDownloadError('Unable to download the file. Please check your internet connection and try again.');
        }
    };



    return (
        <Box id="client-files-container">
            <Grid container mb={2}>
                <Grid item>
                    <Link 
                        style={{ textDecoration: 'none', display: 'flex' }} 
                        href={backLink}
                        className='files-go-back-btn'
                    >
                        <ChevronLeftIcon />
                        Go Back
                    </Link>
                </Grid>
            </Grid>
            <Grid container className="top-section" justifyContent="space-between" alignItems="center">
                <Grid item className="breadcrumb-container" xs={6}>
                    <Breadcrumbs>
                        <Typography className='breadcrumb-main'>All Documents</Typography>
                        <Typography>{folderName}</Typography>
                    </Breadcrumbs>
                </Grid>
                <Grid item className="year-select-container">
                    <FormControl fullWidth variant="outlined" sx={{width: '150px'}}>
                        <InputLabel id="year-select-label">Year</InputLabel>
                        <Select
                            labelId="year-select-label"
                            value={selectedYear}
                            onChange={handleSelectChange}
                            label="Year"
                        >
                            {years.map(year => (
                                <MenuItem key={year} value={year}>
                                    {year}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
            </Grid>
            {loading && (
                <LoadingList />
            )}
            {downloadError && (
                <Alert severity="error" sx={{width: '90%', mt: 2}} onClose={() => setDownloadError(null)}  className='business-alert'>
                {downloadError}
                </Alert>
            )}
            {data && data?.files.length > 0 ? (
                <>
                    <Grid container sx={{width: "100%"}}>
                        <Grid item xs={12}>
                            <List className="file-list">
                                {currentRecords && currentRecords .map((item, i) => (
                                    <ListItem key={i+55} className="file-list-item">
                                        <Link href={item.file_url} target="_blank">
                                            <>
                                                <img src={fileIcons[getFileExtension(item.file_name)] || fileIcons.default} alt="file icon" className='file-icon' />
                                                {item.file_name}
                                            </>
                                        </Link>
                                        <ListItemSecondaryAction>
                                            <IconButton 
                                                onClick={() => 
                                                downloadFile(item._id, item.file_name, item.file_mimetype)}
                                            >
                                                <FileDownloadIcon />
                                            </IconButton>
                                        </ListItemSecondaryAction>
                                    </ListItem>
                                ))}
                            </List>
                        </Grid>
                    </Grid>
                    <Grid container justifyContent="center" mt={4} mb={4}>
                        <Grid item>
                            <PaginationControlled
                                nPages={nPages}
                                currentPage={currentPage}
                                setCurrentPage={setCurrentPage} 
                            />
                        </Grid>
                    </Grid>
                </>
            ) : (
                <ErrorDataComponent msg={error?.data?.error || 'Something went wrong. Please reload the page or contact support if the issue persists.'} />
            )}
        
        </Box>
    )
}

export default GeneralClientFiles;