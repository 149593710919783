import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import Dialog from '@mui/material/Dialog';
import Container from '@mui/material/Container';
import CloseIcon from '@mui/icons-material/Close';
import Chip from '@mui/material/Chip';
import CircularProgress from '@mui/material/CircularProgress';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import Alert from '@mui/material/Alert';

import DialogSuccessMsg from '../../../ui/DialogSuccessMsg';


const AddPersonalFolders = ({ open, setOpen, businessId="", userType, setChildChanges}) => {

    const { userToken } = useSelector((state) => state.user);
    const { clientId, id } = useParams();
    const [newFolders, setNewFolders] = useState([{ name: '', access: '',}]); 
    const [error, setError] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [isSubmitted, setSubmitted] = useState(false);

    const addFolderField = () => {
        setNewFolders([...newFolders, { name: '', access: '' }]);
    };

    const handleRemoveClick = (index) => { // remove index argument
        const updatedFolders = [...newFolders];
        updatedFolders.splice(index, 1);
        setNewFolders(updatedFolders);
    };

    const handleNameChange = (e, index) => {
        const updatedFolders = [...newFolders];
        updatedFolders[index].name = e.target.value;
        setNewFolders(updatedFolders);
    };

    const handleAccessChange = (accessType, index) => {
        const updatedFolders = [...newFolders];
        updatedFolders[index].access = accessType;
        setNewFolders(updatedFolders);
    };

    // useEffect(() => {
    //     if(clientId) {
    //         console.log('Owner', clientId)
    //     }
    // }, [clientId])

    const handleSubmit = async (e) => {
        e.preventDefault()
        setIsLoading(true);

            // Determine folderType and owner based on conditions
        let folderType = null;
        let owner = null;

        if (businessId) {
            folderType = 'business-specific';
            owner = businessId;
        } else if (userType === 'personal') {
            folderType = 'user-specific';
            owner = clientId;
        } else if (userType === 'merged') {
            folderType = 'merged-user-specific';
            owner = clientId;
        } else {
            setError('Unable to determine folder type. Please provide valid information.');
            setIsLoading(false);
            return;
        }

        const data = {
            newFolders: newFolders,
            folderType,
            owner
        }

        console.log(data)

        try {
            const config = {
                headers: {
                    Authorization: `Bearer ${userToken}`,
                },
            };
            const response = await axios.post(
                '/api/client-page/folders/specific/create',
                data,
                config
            );

            if(response.data.success) {
                setSubmitted(true);
                setError('');
                setIsLoading(false);
            } else {
                setError(error.response.data.error || "Something went wrong. Please refresh the page or contact support if the issue persists.");
            }

        } catch (error) {
            console.log(error)
            setError(error.response.data.error || "Something went wrong. Please refresh the page or contact support if the issue persists.");
        } finally {
            setIsLoading(false);
        }
    }

    const handleClose = () => {
        setOpen(false);
        if(isSubmitted) {
            setChildChanges((prevChanges) => !prevChanges);
        }
        setSubmitted(false);
        setError(false);
        setIsLoading(false);
        setNewFolders([{ name: '', access: '',}])
    }

    return (
        <>
            <Dialog
                aria-labelledby="add-folders-modal"
        	    aria-describedby="add-folders-modal"
        	    open={open}
			    onClose={handleClose}
                fullWidth
				id="admin-folders-dialog"
                maxWidth="md"
            >
                <Container sx={{ m: 0, py: 3 }} style={{ overflow: 'auto' }}>
                    <Grid container justifyContent="space-between" alignItems="center" className="upload-dialog-header" mb={3}>
						<Grid item>
							<Chip color="success" label="Add Folders" />
						</Grid>
						<Grid item>
							<IconButton onClick={handleClose}>
								<CloseIcon />
							</IconButton>
						</Grid>
					</Grid>
                    {!isSubmitted ? (
                        <>
                            {error && (
                                <Alert severity="error" onClose={() => setError(null)}  className='business-alert'>
                                    {error}
                                </Alert>
                            )}
                            <Box component="form" onSubmit={handleSubmit}>
                                <Grid container spacing={2}>
                                    {newFolders.map((folder, index) => (
                                        <Grid item xs={12} key={index} mb={2}>
                                            <Grid container alignItems="center" spacing={2}>
                                                <Grid item xs={5}>
                                                    <TextField
                                                        name="name"
                                                        label="Folder Name"
                                                        variant="outlined"
                                                        fullWidth
                                                        size="small"
                                                        value={folder.name}
                                                        onChange={(e) => handleNameChange(e, index)}
                                                    />
                                                </Grid>
                                                <Grid item xs={3}>
                                                    <FormControlLabel 
                                                        control={
                                                        <Checkbox
                                                            checked={folder.access === 'public'}
                                                            onChange={() => handleAccessChange('public', index)}
                                                        />} 
                                                        label="Public"
                                                    />
                                                </Grid>
                                                <Grid item xs={3}>
                                                    <FormControlLabel 
                                                        control={
                                                        <Checkbox
                                                            checked={folder.access === 'private'}
                                                            onChange={() => handleAccessChange('private', index)}
                                                        />} 
                                                        label="Private"
                                                    />
                                                </Grid>
                                                {index !== 0 && (
                                                    <Grid item xs={1}>
                                                        <IconButton onClick={() => handleRemoveClick(index)}>
                                                            <HighlightOffIcon color="error" />
                                                        </IconButton>
                                                    </Grid>
                                                )}
                                            </Grid>
                                        </Grid>
                                    ))}
                                    <Grid item container xs={12} justifyContent="center">
                                        <Grid item>
                                            <IconButton onClick={addFolderField} className="add-folder-btn">
                                                <AddCircleOutlineIcon />
                                            </IconButton>
                                        </Grid> 
                                    </Grid>
                                    <Grid item container xs={12} justifyContent="flex-end">
                                        <Grid item>
                                            <Button 
                                                type="submit" 
                                                variant='contained' 
                                                disabled={isLoading}
                                                className="submit-btn"
                                            >
                                                {isLoading ? (
                                                    <>
                                                    Submit&nbsp;
                                                    <CircularProgress size={18} color="inherit" />
                                                    </>
                                                ) : (
                                                    'Submit'
                                                )}
                                            </Button>
                                        </Grid> 
                                    </Grid>
                                </Grid>
                            </Box>
                        </>
                    ) : (
                        <DialogSuccessMsg msg=" Success! New Folder(s) was created." />
                    )}
                    
                </Container>
            </Dialog> 
        </>
    )
}

export default AddPersonalFolders;