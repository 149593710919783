import React, {useEffect, useState} from 'react';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import Chip from '@mui/material/Chip';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';


const ViewDialogContent = ({ business, onClose }) => {

    // useEffect(() => {
    //     if(business) {
    //         console.log(business)
    //     }
    // }, [business])

    return (

        <Container id="admin-business-view-dialog" className="business-view-container">
            <Box className="business-view-header">
                <Box className="business-view-chips">
                    <Chip label="View Mode" className="chip-view" />
                    {!business.userStatus && <Chip label="Access Frozen" className="chip-frozen" />}
                </Box>
                <IconButton onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            </Box>

            <Box className="business-view-content">
                <Grid container spacing={2}>
                    {business.frozenAt && (
                        <Grid item xs={12} className="business-view-field">
                            <label>Deactivated Since:</label>
                            <span>{business.frozenAt}</span>
                        </Grid>
                    )}
                    <Grid item xs={12} className="business-view-field">
                        <label>Business Name:</label>
                        <span>{business.businessName}</span>
                    </Grid>
                    <Grid item xs={12} className="business-view-field">
                        <label>Business EIN:</label>
                        <span>{business.ein}</span>
                    </Grid>
                    {business.dba && (
                        <Grid item xs={12} className="business-view-field">
                            <label>DBA:</label>
                            <span>{business.dba}</span>
                        </Grid>
                    )}
                    {business.fka && (
                        <Grid item xs={12} className="business-view-field">
                            <label>FKA:</label>
                            <span>{business.fka}</span>
                        </Grid>
                    )}
                </Grid>

                {business.activeUsers.length > 0 && (
                    <Paper className="user-section active-section" elevation={2}>
                        <Typography variant="subtitle2" className="section-header active-header">
                            Active Users
                        </Typography>
                        <Divider />
                        {business.activeUsers.map((user, index) => (
                            <Box className="user-entry" key={index}>
                                <span className="user-name">{user.name}</span>
                                <span className="user-permission">Permissions: {user.permissions}</span>
                            </Box>
                        ))}
                    </Paper>
                )}

                {business.frozenUsers.length > 0 && (
                    <Paper className="user-section frozen-section" elevation={2}>
                        <Typography variant="subtitle2" className="section-header frozen-header">
                            Frozen Users
                        </Typography>
                        <Divider />
                        {business.frozenUsers.map((user, index) => (
                            <Box className="user-entry" key={index}>
                                <span className="user-name">{user.name}</span>
                                <span className="user-permission">Permissions: {user.permissions}</span>
                            </Box>
                        ))}
                    </Paper>
                )}
            </Box>
        </Container>
    )
}

export default ViewDialogContent;