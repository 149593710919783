import React, { useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Chip from '@mui/material/Chip';

import LoadingGrid from '../../../ui/Loading';
import EmptyDataComponent from '../../../ui/EmptyDataComponent';
import BusinessSettingsModal from './BusinessSettingsModal';
import BusinessSettingsModal2 from './BusinessSettingsModal2';
import PaginationControlled from '../../../ui/Pagination';

import { useFetchData } from "../../../../hooks/useFetchData";

const BusinessAccountIntegration = ({ userToken, searchQue }) => {

    const [childChanges, setChildChanges] = useState(false);
    const [loading, data, error] = useFetchData('/api/admin/businesses/all', userToken, childChanges);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedBusiness, setSelectedBusiness] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [recordsPerPage] = useState(9);


    const handleBusinessClick = (business) => {
        setSelectedBusiness(business);
        setIsModalOpen(true);
    };

    // useEffect(() => {
    //     if(selectedBusiness) {
    //         console.log(selectedBusiness)
    //     }
    // }, [selectedBusiness])

    
    const searchedList = !searchQue
    ? data
    : data.filter(business => 
        business.businessName.toLowerCase().includes(searchQue.toLowerCase()) 
	)

    const indexOfLastRecord = currentPage * recordsPerPage;
    const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
    const currentRecords = searchedList ? searchedList.slice(indexOfFirstRecord, indexOfLastRecord) : [];
    const nPages = searchedList ? Math.ceil(searchedList.length / recordsPerPage) : 0;

    if (loading) {
        return <LoadingGrid />;
    }

    if(data && data.length === 0) {
        return (
            <EmptyDataComponent msg="It looks like you do not have any businesses yet. Once you do, you'll see them listed here." />
        )
    }

    return (
        <Box id="businesses-integration-wrapper">
            <Grid container className='businesses-container' justifyContent="start" spacing={4}>
                {currentRecords && currentRecords.map((business, index) => (
                    <Grid item xs={12} sm={6} md={4} key={business._id}>
                        <Paper className="business-card" onClick={() => handleBusinessClick(business._id)}>
                            <Box className="business-header">
                                <Typography variant="h6" className="business-name">{business.businessName}</Typography>
                                <Typography variant="body2" className="business-ein">EIN: {business.ein}</Typography>
                            </Box>
              
                            <Box className="business-info">
                                <Box className="status-group status">
                                    <Typography className="label">Status:</Typography>
                                    <Box className={`status-pill ${business.status?.toLowerCase() || 'active'}`}>
                                        {business.status || 'Active'}
                                    </Box>
                                </Box>
                                <Box className="status-group">
                                    <Typography className="label">Active Users:</Typography>
                                    <Chip label={business.activeUserCount} className="count-chip green" size="small" />
                                </Box>
                                <Box className="status-group">
                                    <Typography className="label">Frozen Users:</Typography>
                                    <Chip label={business.frozenUserCount} className="count-chip red" size="small" />
                                </Box>
                            </Box>
                        </Paper>
                    </Grid>
                  
                ))}
               <Grid container justifyContent="center" mt={4}>
                    <Grid item>
                        <PaginationControlled
                            nPages={nPages}
                            currentPage={currentPage}
                            setCurrentPage={setCurrentPage} 
                        />
                    </Grid>
                </Grid>
            </Grid>
            <BusinessSettingsModal2
                open={isModalOpen}
                setOpen={setIsModalOpen}
                businessId={selectedBusiness}
                userToken={userToken}
                updateBusinessList={setChildChanges}
                view="general"
            />
        </Box>
    )
}

export default BusinessAccountIntegration;