import React, { useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import { Link } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import AddIcon from '@mui/icons-material/Add';
import Typography from '@mui/material/Typography';
import Chip from '@mui/material/Chip';
import SettingsIcon from '@mui/icons-material/Settings';
import VisibilityIcon from '@mui/icons-material/Visibility';


import AddNewBusiness from './AddNewBusiness';
import ClientBusinessDialog from './BusinessDialog';

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(0, 2, 2, 2),
    color: theme.palette.text.secondary,
}));

const MyBusiness = ({ userToken, id, businesses, setBusinesses, setChildChanges }) => {

    const [isAddNewOpen, setIsAddNewOpen] = useState(false)
    const [openBusinessMenu, setOpenBusinessMenu] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [currentBusiness, setCurrentBusiness] = useState(null);
    const [dialog, setDialog] = useState(null);

    useEffect(() => {
        if(businesses) {
            console.log(businesses)
        }
    }, [businesses])
  
    const handleBusinessMenuOpen = (event, item) => {
      event.stopPropagation();
      setAnchorEl(event.currentTarget);
      setCurrentBusiness(item);
      setOpenBusinessMenu(true);
    };
  
    const handleBusinessMenuClose = (event) => {
      setAnchorEl(null);
      setOpenBusinessMenu(false);
    };

    const handleAddNewOpen = (event) => {
        event.stopPropagation();
        setIsAddNewOpen(true);
    }
    
    const handleDelete = (event) => {
        event.stopPropagation();
        setDialog('delete');
        setOpenBusinessMenu(false);
    };
    
    const handleView = (event) => {
        event.stopPropagation();
        setDialog('view');
        setOpenBusinessMenu(false);
    };

    const handleDialogClose = (event) => {
        event.stopPropagation();
        setDialog(null);
    }

    const updateBusiness = (updatedBusiness) => {
        setBusinesses((prevBusinesses) => {
          return prevBusinesses.map((business) => {
            if (business._id === updatedBusiness._id) {
              return updatedBusiness;
            }
            return business;
          });
        });
    };

    const deleteBusiness = (businessId) => {
        setBusinesses((prevBusinesses) => {
          return prevBusinesses.filter((business) => business._id !== businessId);
        });
    };


  
    const BusinessMenu = ({ anchorEl, open, onClose  }) => (
        <Menu anchorEl={anchorEl} open={open} onClose={onClose} className='business-menu'>
            <MenuItem onClick={handleView}>
                <VisibilityIcon/>
                View
            </MenuItem>
            <MenuItem component={Link} to='/settings?business=settings'>
                <SettingsIcon />
                Settings
            </MenuItem>
            {/* <MenuItem key="delete" onClick={handleDelete}>Archive</MenuItem>, */}
        </Menu>
    );
  
   
    return (
        <Box className="section-content business-info" sx={{ width: '100%' }}>
            <Grid container alignItems="center" rowSpacing={1} columnSpacing={{ xs: 1, sm: 2 }}>
                {businesses.filter(item => item.status === 'active').map((item, index) => (
                    <Grid item xs={12} sm={6} md={5} lg={4} key={index}>
                        <Item>
                            <Grid container alignItems="center" justifyContent={!item.userStatus ? 'space-between' : 'flex-end'}>
                                {!item.userStatus && (
                                    <Grid item>
                                        <Chip label="Not active" color='error' variant="contained"/>
                                    </Grid>
                                )}
                                <Grid item>
                                    <IconButton
                                        onClick={(e) => { e.stopPropagation(); handleBusinessMenuOpen(e, item); }}
                                    >
                                        <MoreHorizIcon />
                                    </IconButton>
                                </Grid>
                            </Grid>
                            <Box className="field">
                                <label htmlFor="lastName">Business Name:</label>
                                <span id="lastName">{item.businessName}</span>
                            </Box>
                        </Item>
                    </Grid>
                ))}
                <Grid item xs={1} container>
                    <Grid item>
                        <IconButton onClick={handleAddNewOpen}>
                            <AddIcon />
                        </IconButton>
                    </Grid>
                </Grid>
            </Grid>
            <BusinessMenu
                anchorEl={anchorEl}
                open={openBusinessMenu}
                onClose={(e) => { e.stopPropagation(); handleBusinessMenuClose(); setDialog(null); }}
                isArchived={currentBusiness?.status === 1}
            />
            <AddNewBusiness
                open={isAddNewOpen} 
                setOpen={setIsAddNewOpen}
                setBusinesses={setBusinesses}
                userToken={userToken} 
                id={id} 
                setChildChanges={setChildChanges}
            />
            <ClientBusinessDialog 
                dialogType={dialog} 
                business={currentBusiness} 
                onClose={handleDialogClose} 
                userToken={userToken} 
                updateBusiness={updateBusiness}
                deleteBusiness={deleteBusiness}
            />
      </Box>
    );
  };
  
  export default MyBusiness;