import React, { useState, useEffect} from "react";
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useLocation, useNavigate, Outlet, Link } from "react-router-dom";
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Typography from "@mui/material/Typography";
import Tooltip from '@mui/material/Tooltip';
import PersonIcon from '@mui/icons-material/Person';
import HistoryIcon from '@mui/icons-material/History';
import FolderCopyIcon from '@mui/icons-material/FolderCopy';
import PaidIcon from '@mui/icons-material/Paid';
import Collapse from '@mui/material/Collapse';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import WorkIcon from '@mui/icons-material/Work';
import HomeIcon from '@mui/icons-material/Home';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import FileCopyIcon from '@mui/icons-material/FileCopy';

import { useFetchData } from '../../../hooks/useFetchData';
import { fetchAdminClientDetails } from "../../../actions/adminClientActions";

import DesktopDrawer from "./DesktopDrawer";
import MobileDrawer from "./MobileDrawer";

const drawerWidth = 240;

const openedMixin = (theme) => ({
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
});
  
const closedMixin = (theme) => ({
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up('sm')]: {
      width: `calc(${theme.spacing(8)} + 1px)`,
    },
});
  
const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
}));
  
const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
      width: drawerWidth,
      flexShrink: 0,
      whiteSpace: 'nowrap',
      boxSizing: 'border-box',
      ...(open && {
        ...openedMixin(theme),
        '& .MuiDrawer-paper': openedMixin(theme),
      }),
      ...(!open && {
        ...closedMixin(theme),
        '& .MuiDrawer-paper': closedMixin(theme),
      }),
    }),
);

const ClientPage = () => {

    const [open, setOpen] = useState(true);
    const [isDocumentsOpen, setDocumentsOpen] = useState(false);
    const { clientId } = useParams();
    const location = useLocation();
    const dispatch = useDispatch();
    const { clientDetails, loading, error } = useSelector((state) => state.adminClient);

    const currentPath = location.pathname;
    const isClientPage = currentPath.startsWith('/admin/client-page/');
    const isMergeClientPage = currentPath.startsWith('/admin/merge/client-page/');
    const userType = isClientPage ? 1 : 2;


    useEffect(() => {
        dispatch(fetchAdminClientDetails({ clientId: clientId, userType }));
    }, [dispatch, clientId, userType]);

    const handleDrawer = () => {
		setOpen(!open);
	};

    const handleDocumentsMenu = () => {
        setDocumentsOpen(!isDocumentsOpen)
    }

    // useEffect(() => {
    //     if(clientId) {
    //         console.log(clientId)
    //     }
    // }, [clientId])

    // Automatically set isDocumentsOpen based on URL
    useEffect(() => {
        if (
            location.pathname.startsWith("/admin/client-page/business/documents/") || 
            location.pathname.startsWith("/admin/client-page/documents/")
        ) {
            setDocumentsOpen(true);
        }
    }, [location.pathname]); // Runs every time the URL changes


    return (
        <Box sx={{ display: 'flex' }} id="drawer-container">
      		<Drawer variant="permanent" open={open}>
                {open ? (
                    <DesktopDrawer 
                        handleDrawer={handleDrawer}
                        handleDocumentsMenu={handleDocumentsMenu}
                        isDocumentsOpen={isDocumentsOpen}
                        clientId={clientId}
                        isClientPage={isClientPage}
                        clientDetails={clientDetails}
                    />
                ) : (
                    <MobileDrawer 
                        handleDrawer={handleDrawer}
                        handleDocumentsMenu={handleDocumentsMenu}
                        isDocumentsOpen={isDocumentsOpen}
                        id={clientId}
                        isClientPage={isClientPage}
                        clientDetails={clientDetails}
                    />
                )}

            </Drawer>
            <Box component="main" sx={{ flexGrow: 1, p: 3 }} className="admin-dashboard">
                <Outlet />
      		</Box>
        </Box>
    )
}

export default ClientPage;