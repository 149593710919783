import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useSelector } from 'react-redux';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import Dialog from '@mui/material/Dialog';
import Container from '@mui/material/Container';
import CloseIcon from '@mui/icons-material/Close';
import Chip from '@mui/material/Chip';
import CircularProgress from '@mui/material/CircularProgress';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';

import SuccessIcon from '../../../assets/icons/check.png'


const AddFolders = ({ open, setOpen, userToken, setFolders, folders, setChildChanges }) => {

    const [newFolders, setNewFolders] = useState([{ name: '', access: '',}]); 
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);
    const [submittedData, setSubmittedData] = useState(false);

 
    const addFolderField = () => {
        setNewFolders([...newFolders, { name: '', access: '' }]);
    };

    const handleRemoveClick = (index) => { // remove index argument
        const updatedFolders = [...newFolders];
        updatedFolders.splice(index, 1);
        setNewFolders(updatedFolders);
    };

    const handleNameChange = (e, index) => {
        const updatedFolders = [...newFolders];
        updatedFolders[index].name = e.target.value;
        setNewFolders(updatedFolders);
    };

    const handleAccessChange = (accessType, index) => {
        const updatedFolders = [...newFolders];
        updatedFolders[index].access = accessType;
        setNewFolders(updatedFolders);
    };

    const handleSubmit = async (e) => {
        e.preventDefault()
        setLoading(true);

        try {
            const config = {
                headers: {
                    Authorization: `Bearer ${userToken}`,
                },
            };
            const response = await axios.post(
                '/api/admin/folders',
                newFolders,
                config
            );
            if (response.data.error) {
                setError(response.data.error);
            } else {
                setSubmittedData(true);
                setError('');
                setFolders([...folders, ...newFolders]);
            }
        } catch (error) {
            console.log('Error')
            setError('Error')
        } finally {
            setLoading(false);
        }
    }

    const handleClose = () => {
        setOpen(false);
        if(submittedData) {
            setChildChanges((prevChanges) => !prevChanges);
        }
        setSubmittedData(false);
        setError(false);
        setLoading(false);
        setNewFolders([{ name: '', access: '',}])
    }
    
    return (
        <>
            <Dialog
                aria-labelledby="add-folders-modal"
        	    aria-describedby="add-folders-modal"
        	    open={open}
			    onClose={handleClose}
                fullWidth
				id="admin-folders-dialog"
                maxWidth="md"
            >
                <Container sx={{ m: 0, py: 3 }} style={{ overflow: 'auto' }}>
                    <Grid container justifyContent="space-between" alignItems="center" className="upload-dialog-header" mb={3}>
						<Grid item>
							<Chip color="success" label="Add Folders" />
						</Grid>
						<Grid item>
							<IconButton onClick={handleClose}>
								<CloseIcon />
							</IconButton>
						</Grid>
					</Grid>
                    {!submittedData ? (
                        <>
                            {error && <Typography className='dialog-error-msg' color="error" variant='body1' textAlign="center">{error}</Typography>}
                            <Box component="form" onSubmit={handleSubmit}>
                                <Grid container spacing={2}>
                                    {newFolders.map((folder, index) => (
                                        <Grid item xs={12} key={index} mb={2}>
                                            <Grid container alignItems="center" spacing={2}>
                                                <Grid item xs={5}>
                                                    <TextField
                                                        name="name"
                                                        label="Folder Name"
                                                        variant="outlined"
                                                        fullWidth
                                                        size="small"
                                                        value={folder.name}
                                                        onChange={(e) => handleNameChange(e, index)}
                                                    />
                                                </Grid>
                                                <Grid item xs={3}>
                                                    <FormControlLabel 
                                                        control={
                                                        <Checkbox
                                                            checked={folder.access === 'public'}
                                                            onChange={() => handleAccessChange('public', index)}
                                                        />} 
                                                        label="Public"
                                                    />
                                                </Grid>
                                                <Grid item xs={3}>
                                                    <FormControlLabel 
                                                        control={
                                                        <Checkbox
                                                            checked={folder.access === 'private'}
                                                            onChange={() => handleAccessChange('private', index)}
                                                        />} 
                                                        label="Private"
                                                    />
                                                </Grid>
                                                {index !== 0 && (
                                                    <Grid item xs={1}>
                                                        <IconButton onClick={() => handleRemoveClick(index)}>
                                                            <HighlightOffIcon color="error" />
                                                        </IconButton>
                                                    </Grid>
                                                )}
                                            </Grid>
                                        </Grid>
                                    ))}
                                    <Grid item container xs={12} justifyContent="center">
                                        <Grid item>
                                            <IconButton onClick={addFolderField} className="add-folder-btn">
                                                <AddCircleOutlineIcon />
                                            </IconButton>
                                        </Grid> 
                                    </Grid>
                                    <Grid item container xs={12} justifyContent="flex-end">
                                        <Grid item>
                                            <Button 
                                                type="submit" 
                                                variant='contained' 
                                                disabled={loading}
                                                className="submit-btn"
                                            >
                                                {loading ? (
                                                    <>
                                                    Submit&nbsp;
                                                    <CircularProgress size={18} color="inherit" />
                                                    </>
                                                ) : (
                                                    'Submit'
                                                )}
                                            </Button>
                                        </Grid> 
                                    </Grid>
                                </Grid>
                            </Box>
                        </>
                    ) : (
                        <Box className='success-msg'>
                            <Grid container flexDirection="column" alignItems="center" justifyContent="center">
                                <Grid item mb={2}>
                                    <img src={SuccessIcon} />
                                </Grid>
                                <Grid item>
                                    <Typography>
                                        Success! New Folder(s) was created.
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Box>
                    )}
                    
                </Container>
            </Dialog> 
        </>
    )
}

export default AddFolders;