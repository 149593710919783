import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Grid';
import Grid from '@mui/material/Grid';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Avatar from '@mui/material/Avatar';
import Tooltip from '@mui/material/Tooltip';

import {generateUniqueKey } from '../../../utils/generateKey';

const SpecificGroupQuestionsView = ({ data, questionGroup, handleEditOpen, handleDeleteOpen }) => {
    return(
        <>
            {data
            .filter(question => question.group.toLowerCase() === questionGroup.toLowerCase())
            .sort((que1, que2) => que1.order - que2.order)
            .map((question, index) => (
                <Box className="questionnaire-item" key={generateUniqueKey()}>
                    <Grid container alignItems="center">
                        <Grid item>
                            <Avatar className="questionnaire-avatar">{question.order}</Avatar>
                        </Grid>
                        <Grid item xs className="questionnaire-question-text" pl="16px">
                            <Typography>{question.text} <span className="question-type">({question.type === 'general' ? 'Yes/No' : 'Textfield'})</span></Typography>
                        </Grid>
                        <Grid item>
                            <Tooltip title="Edit">
                                <IconButton onClick={() => handleEditOpen(question, index)}>
                                    <EditIcon color="primary" />
                                </IconButton>
                            </Tooltip>
                            <Tooltip title="Delete">
                                <IconButton onClick={() => handleDeleteOpen(question, index)}>
                                    <DeleteIcon color='error' />
                                </IconButton>
                            </Tooltip>
                        </Grid>
                    </Grid>
                    {question.subQuestion && question.subQuestion.length > 0 && (
                        <Grid item xs={12} container className="subquestion-container">
                            <Typography className="subquestion-title">
                                Sub Questions:
                            </Typography>
                            {question.subQuestion.map((item, index) => (
                                <Grid xs={12} item key={generateUniqueKey()}>
                                    <Typography className="subquestion-item">
                                        {index + 1}. {item.subQuestionText}
                                        <span className="question-type"> ({item.subQuestionType === 'general' ? 'Yes/No' : 'Textfield'})</span>
                                    </Typography>
                                </Grid>
                            ))}
                        </Grid>
                    )}
                    {question.note && (
                        <Grid item xs={12} container className="note-container">
                            <Grid item xs={12}>
                                <Typography xs={12} component='p' className="note-title">
                                    Note for Questionnaire:
                                </Typography>
                            </Grid>
                            <Typography component='p' className="note-text">
                                {question.note}
                            </Typography>
                        </Grid>
                    )}
                    {question.noteForDocuments && (
                        <Grid item xs={12} container className="note-container note-docs">
                            <Grid item xs={12}>
                                <Typography xs={12} component='p' className="note-title">
                                    Note for Documents Upload:
                                </Typography>
                            </Grid>
                            <Typography component='p' className="note-text">
                                {question.noteForDocuments}
                            </Typography>
                        </Grid>
                    )}
                </Box>
            ))}
        </>
    );
};

export default SpecificGroupQuestionsView;