import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import ClientProfile from "./ClientProfile";
import ClientQuestionnaires from "./ClientQuestionnaires";
import ClientDocs from "./ClientDocuments";
import BusinessDocumentsSummary from "./BusinessDocuments";
import ClientRefunds from "./ClientRefunds";
import ClientPage from './index';
import PersonalClientDocuments from './ClientDocuments/PersonalClientDocuments';
import BusinessClientDocuments from './BusinessDocuments/BusinessClientDocuments';



const AdminClientPageRoutes = () => {

    return (
        <Routes>
            <Route path="/" element={<ClientPage />}>
                <Route index element={<Navigate replace to="client-page/:clientId" />} />
                <Route path="client-page/:clientId" element={<ClientProfile />} />
                <Route path="client-page/documents/:clientId/folder/questionnaire" element={<ClientQuestionnaires />} />
                <Route path="client-page/documents/:clientId" element={<ClientDocs />} />
                <Route path="client-page/documents/:clientId/folder/:folderName/year/:year" element={<PersonalClientDocuments />} />
                <Route path="client-page/business/documents/:clientId" element={<BusinessDocumentsSummary />} />
                <Route path="client-page/business/documents/:clientId/:businessId/folder/:folderName/year/:year" element={<BusinessClientDocuments />} />
                <Route path="client-page/refunds/:clientId" element={<ClientRefunds />} />
            </Route>
        </Routes>
    );
};
  
export default AdminClientPageRoutes;