import React, {useEffect, useState} from 'react';
import axios from 'axios';
import Container from '@mui/material/Container';
import Dialog from '@mui/material/Dialog';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid'
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import Chip from '@mui/material/Chip';
import CircularProgress from '@mui/material/CircularProgress';
import Alert from '@mui/material/Alert';

import GeneralAddFilesFields from './general-add-files-fields';


import DialogSuccessMsg from '../../ui/DialogSuccessMsg';



const GeneralClientAddFiles = ({ open, setOpen, userToken, businessId, businessName, setChildChanges, userType, clientId, mergedClientId }) => {

    const [newUserFiles, setNewUserFiles] = useState({
    	userFiles: [],
  	});
    const [errorMsg, setErrorMsg] = useState('');
	const [loading, setLoading] = useState(false);
	const [isUploaded, setIsUploaded] = useState(false);

    const updateUploadedFiles = (files) => {
        setNewUserFiles({ ...newUserFiles, userFiles: files });
    }

    const handleClose = () => {
		if(isUploaded) {
			setChildChanges((prevChanges) => !prevChanges);
		}
        setOpen(false);
		setIsUploaded(false);
		setErrorMsg('');
    }

    const handleOnSubmit = async (e) => {
    	e.preventDefault();

		if (newUserFiles.userFiles.length === 0) {
			setErrorMsg('No files selected. Please select at least one file to upload.');
			return;  
		}

		setLoading(true);
		setErrorMsg('');

    	try {

			const formData = new FormData();
			for (let i = 0 ; i < newUserFiles.userFiles.length ; i++) {
				formData.append("files", newUserFiles.userFiles[i]);
			}

			let url;

			
			if (userType === 'business' && businessId ) {
				url = `/api/c/dashboard/files/upload/b/business/${businessId}`
			} else if (userType === 'personal' && clientId) {
				url = `/api/c/dashboard/files/upload/p/personal/${clientId}`
			} else if (userType === 'merged' && mergedClientId) {
				url =  `/api/c/dashboard/files/upload/m/merged/${mergedClientId}`
			} else {
				setErrorMsg('Something went wrong. Please refresh the page or contact support if the issue persists.1');
				return;
			}

			console.log(url)

			const response = await axios.post(url, formData, {
				headers: {
					Authorization: `Bearer ${userToken}`,
					'Content-Type': 'multipart/form-data'
				}
			});

			if(response.data.success) {
                setIsUploaded(true);
				setErrorMsg('');
				setLoading(false);
            } else {
				setIsUploaded(false);
                setErrorMsg(error.response.data.error || "Something went wrong. Please refresh the page or contact support if the issue persists.2");
            }
   
    	} catch (error) {
			console.log(error)
			setErrorMsg('Something went wrong. Please refresh the page or contact support if the issue persists.3');
    	} finally {
			setLoading(false);
		}
  	}

    return (
        <>
            <Dialog
        	    aria-labelledby="client-documents-upload-dialog"
        	    aria-describedby="client-documents-upload-dialog"
        	    open={open}
			    onClose={handleClose}
                fullWidth
				id="client-documents-upload-dialog"
                maxWidth="md"
      	    >
				<Container sx={{ m: 0, p: 2 }} style={{ overflow: 'auto' }}>
					<Grid container justifyContent="space-between" alignItems="center" className="upload-dialog-header">
						<Grid item>
							<Chip color="warning" label={businessName ? `${businessName} File Upload` : 'Personal File Upload'} />
						</Grid>
						<Grid item>
							<IconButton onClick={handleClose}>
								<CloseIcon />
							</IconButton>
						</Grid>
					</Grid>
					{errorMsg && (
						<Grid container mt={3}>
							<Grid item xs={12}>
								<Alert severity="error" onClose={() => setErrorMsg(null)}  className='business-alert'>
						 			{errorMsg}
					 			</Alert>
							</Grid>
						</Grid>
					)}
					{isUploaded ? (
						<Box className="admin-doc-modal">
							<DialogSuccessMsg msg="Files uploaded successfully" />
						</Box>
					) : (
						<Box mt={5} mb={5} className="admin-doc-modal" component="form" onSubmit={handleOnSubmit}>
							<GeneralAddFilesFields label="Documents Upload" multiple updateFilesCb={updateUploadedFiles} />
							<Grid container justifyContent="flex-end" sx={{width: '95%'}}>
								<Grid item>
									<Button 
										className='add-files-btn' 
										type="submit"
										disabled={loading}
									> 
										{loading ? (
                                            <>
                                                Submit&nbsp;
                                                <CircularProgress size={18} color="inherit" />
                                            </>
                                        ) : (
                                            'Submit'
                                        )}
									</Button>
								</Grid>
							</Grid>
						</Box>
					)}
				</Container>
      		</Dialog>
        </>
    )
}

export default GeneralClientAddFiles;