import React, { useEffect, useState } from 'react';
import axios from 'axios';
import io from 'socket.io-client';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import NotificationsIcon from '@mui/icons-material/Notifications';
import DeleteIcon from '@mui/icons-material/Close';
import Alert from '@mui/material/Alert';

import LoadingList from '../../ui/LoadingList';

const SOCKET_URL = process.env.NODE_ENV === 'production' ? process.env.REACT_APP_SOCKET_URL : 'http://localhost:5001';

const socket = io(SOCKET_URL, {
    withCredentials: true,
    transports: ['websocket', 'polling'],
});

const GeneralClientNotifications = ({ userId, userToken, userType }) => {


    const [notifications, setNotifications] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);  


    useEffect(() => {
        const fetchNotifications = async () => {
        try {
            const config = {
                headers: { Authorization: `Bearer ${userToken}` },
            };
            const url =
                userType === 'merged'
                    ? `/api/c/dashboard/merged/notifications/${userId}`
                    : '/api/c/dashboard/personal/notifications';

            const response = await axios.get(url, config);
            setNotifications(response.data.notifications);
            setLoading(false);
        } catch (err) {
            setError('Failed to load notifications.');
            setLoading(false);
        }
        };

        if (userId) {
        fetchNotifications();
        }
    }, [userId]);

    // WebSocket live updates
    useEffect(() => {
        if (!userId) return;

        socket.emit('join', userId);
        socket.on('new_notification', (notification) => {
            setNotifications((prev) => [notification, ...prev]);
        });

        return () => {
            socket.off('new_notification');
            socket.emit('leave', userId);
        };
    }, [userId]);

    // Delete a single notification
    const handleDelete = async (id) => {
        try {

            const url = userType === 'merged' ? `/api/c/dashboard/merged/notifications/${userId}/${id}` : `/api/c/dashboard/personal/notifications/${id}`;
            await axios.delete(url, {
                headers: { Authorization: `Bearer ${userToken}` },
            });
            setNotifications((prev) => prev.filter((n) => n._id !== id));
        } catch (err) {
            console.error('Delete failed:', err);
        }
    };

    // Delete all notifications
    const handleDeleteAll = async () => {
        try {
            const url = userType === 'merged' ? `/api/c/dashboard/merged/notifications/${userId}` : '/api/c/dashboard/personal/notifications';
            await axios.delete(url, {
                headers: { Authorization: `Bearer ${userToken}` },
            });
            setNotifications([]);
        } catch (err) {
            console.error('Delete all failed:', err);
        }
    };

    if (loading) return <LoadingList />;
    if (error) return <Alert severity="error">{error}</Alert>;


    return (
        <Box className='notifications'>
            {notifications && notifications.length > 0 ? (
                <Box ml={2} mb={3} className="notifications-wrapper" id="notifications-wrapper">
                    <Box display="flex" justifyContent="end" alignItems="center" mb={2}>
                        <Button
                            variant="outlined"
                            color="error"
                            size="small"
                            onClick={handleDeleteAll}
                        >
                            Delete All
                        </Button>
                    </Box>
        
                    <List className="notification-list">
                        {notifications.map((notification) => (
                            <ListItem
                                key={notification._id}
                                className={`notification-item ${notification.isRead ? 'read' : 'unread'}`}
                                alignItems="flex-start"
                                secondaryAction={
                                    <IconButton
                                        edge="end"
                                        aria-label="delete"
                                        onClick={() => handleDelete(notification._id)}
                                    >
                                        <DeleteIcon fontSize="small" />
                                    </IconButton>
                                }
                            >
                                <ListItemIcon>
                                    <NotificationsIcon color={notification.isRead ? 'action' : 'primary'} />
                                </ListItemIcon>

                                <ListItemText
                                    primary={
                                        <Typography variant="subtitle1" fontWeight={600}>
                                            {notification.title || 'Notification'}
                                        </Typography>
                                    }
                                    secondary={
                                        <>
                                            <Typography variant="body2" color="textSecondary">
                                                {notification.message}
                                            </Typography>
                                            <Typography variant="caption" color="textSecondary" class="notification-date">
                                                {notification.formattedCreatedAt}
                                            </Typography>
                                        </>
                                    }
                                />
                        </ListItem>
                        ))}
                    </List>
                </Box>
            ) : (
                <Typography ml={2} mb={3} className='no-notifications-text'>You do not have any notifications yet.</Typography>
            )}
       </Box>
    );
};

export default GeneralClientNotifications;