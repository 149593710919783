import React, {useEffect, useState} from 'react'
import { useSelector } from 'react-redux';
import { useParams, useLocation, useNavigate, Outlet, Link } from "react-router-dom";
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Typography from "@mui/material/Typography";
import Tooltip from '@mui/material/Tooltip';
import PersonIcon from '@mui/icons-material/Person';
import HistoryIcon from '@mui/icons-material/History';
import FolderCopyIcon from '@mui/icons-material/FolderCopy';
import PaidIcon from '@mui/icons-material/Paid';
import Collapse from '@mui/material/Collapse';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import WorkIcon from '@mui/icons-material/Work';
import HomeIcon from '@mui/icons-material/Home';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import FileCopyIcon from '@mui/icons-material/FileCopy';

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
}));


const DesktopDrawer = ({ handleDrawer, clientId, handleDocumentsMenu, isDocumentsOpen, isClientPage, clientDetails }) => {

    const location = useLocation();

    const checkIsActive = (path) => {
        // Split the given path into pathname and search parts
        const [pathName, search = ''] = path.split('?');
    
        // Check if pathname matches
        if (location.pathname !== pathName) {
            return false;
        }
    
        // If there are query parameters in the given path, check them too
        if (search && location.search !== '?' + search) {
            return false;
        }
    
        return true;
    };

    const checkIsActive2 = (path) => {
        return location.pathname.startsWith(path);
    };

    return (
        <>
        <DrawerHeader className="client-profile-drawer-header">
            {isClientPage && clientDetails && (
                <Typography className="single-user-page" sx={{textTransform: 'capitalize'}}>
                    {clientDetails.firstName} {clientDetails.lastName}
                </Typography>
            )}
            {!isClientPage && clientDetails && clientDetails.user1 && clientDetails.user2  && (
                <Box className="merged-user-drawer-wrapper">
                    <Typography className="merged-user-page" sx={{textTransform: 'capitalize'}}>
                        {clientDetails.user1.firstName} {clientDetails.user1.lastName}
                    </Typography>
                    <Typography className="merged-user-page" sx={{textTransform: 'capitalize'}}>
                        {clientDetails.user2.firstName} {clientDetails.user2.lastName}
                    </Typography>
                </Box> 
            )}
            <IconButton onClick={handleDrawer}>
                <ChevronLeftIcon />
            </IconButton>
        </DrawerHeader>
        <Divider />
        <List className="list-open">
            <ListItem sx={{ display: 'block' }}>
                <ListItemButton 
                    component={Link} 
                    to='/admin-profile'
                    sx={{
                        minHeight: 48,
                        justifyContent: 'initial',
                        px: 2.5,
                        pr: "10px",
                        pl: "10px"
                    }}
                    className="list-group-item"
                >
                    <ListItemIcon
                        sx={{
                            minWidth: 0,
                            mr: 2,
                            justifyContent: 'center',
                        }}
                    >
                        <HomeIcon />
                    </ListItemIcon>
                    <ListItemText primary="Clients List" sx={{ display: "block"}}/>
                </ListItemButton>
            </ListItem>
            <ListItem sx={{ display: 'block' }}>
                <ListItemButton
                    component={Link} 
                    to={isClientPage ? `/admin/client-page/${clientId}` : `/admin/merge/client-page/${clientId}`}
                    sx={{
                        minHeight: 48,
                        justifyContent: 'initial',
                        px: 2.5,
                        pr: "10px",
                        pl: "10px"
                    }}
                    className={`list-group-item ${checkIsActive(isClientPage ? `/admin/client-page/${clientId}` : `/admin/merge/client-page/${clientId}`) ? 'active' : ''}`}
                >
                    <ListItemIcon
                        sx={{
                            minWidth: 0,
                            mr: 2,
                            justifyContent: 'center',
                        }}
                    >
                        <AccountBoxIcon />
                    </ListItemIcon>
                    <ListItemText primary="Client Profile" sx={{ display: "block"}}/>
                </ListItemButton>
            </ListItem>
            {/* <ListItem sx={{ display: 'block' }}>
                <ListItemButton
                    component={Link} 
                    to={isClientPage ? `/admin/client-page/history/${clientId}` :  `/admin/merge/client-page/history/${clientId}`}
                    sx={{
                        minHeight: 48,
                        justifyContent: 'initial',
                        px: 2.5,
                        pr: "10px",
                        pl: "10px"
                    }}
                    className={`list-group-item ${checkIsActive(isClientPage ? `/admin/client-page/history/${clientId}` : `/admin/merge/client-page/history/${clientId}`) ? 'active' : ''}`}
                >
                    <ListItemIcon
                        sx={{
                            minWidth: 0,
                            mr: 2,
                            justifyContent: 'center',
                        }}
                    >
                       <HistoryIcon />
                    </ListItemIcon>
                    <ListItemText primary="History" sx={{ display: "block"}}/>
                </ListItemButton>
            </ListItem> */}
            <Divider />
            <ListItem sx={{ display: 'block' }}>
                <ListItemButton
                    sx={{
                        minHeight: 48,
                        justifyContent: 'initial',
                        px: 2.5,
                        pr: "10px",
                        pl: "10px"
                    }}
                    className="list-group-item"
                    onClick={handleDocumentsMenu}
                >
                    <ListItemIcon
                        sx={{
                            minWidth: 0,
                            mr: 2,
                            justifyContent: 'center',
                        }}
                    >
                       <FileCopyIcon />
                    </ListItemIcon>
                    <ListItemText primary="Documents" sx={{ display: "block"}}/>
                    <IconButton>
                        <ExpandMoreIcon />
                    </IconButton>
                </ListItemButton>
            </ListItem>
            {isDocumentsOpen && (
                <Collapse in={isDocumentsOpen} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                        <ListItem sx={{pl: '40px'}}>
                            <ListItemButton
                                component={Link} 
                                to={isClientPage ? `/admin/client-page/documents/${clientId}` :  `/admin/merge/client-page/documents/${clientId}`}
                                sx={{
                                    minHeight: 48,
                                    justifyContent: 'initial',
                                    px: 2.5,
                                    pr: "10px",
                                    pl: "10px"
                                }}
                                // className={`list-group-item ${checkIsActive(isClientPage ? `/admin/client-page/documents/${clientId}` : `/admin/merge/client-page/documents/${clientId}`) ? 'active' : ''}`}
                                className={`list-group-item ${isClientPage && checkIsActive2(`/admin/client-page/documents/${clientId}`) ? 'active' : ''}`}
                            >
                                <ListItemIcon
                                    sx={{
                                        minWidth: 0,
                                        mr: 2,
                                        justifyContent: 'center',
                                    }}
                                >
                                    <PersonIcon />
                                </ListItemIcon>
                                <ListItemText primary="Personal" sx={{ display: "block"}}/>
                            </ListItemButton>
                        </ListItem>
                        {isClientPage && !!clientDetails && clientDetails.businesses.length > 0 && clientDetails.businesses.map((business, index) => (
                            <ListItem sx={{pl: '40px'}} key={index}>
                                <ListItemButton
                                    component={Link} 
                                    to={`/admin/client-page/business/documents/${clientId}?businessId=${business._id}`}
                                    sx={{
                                        minHeight: 48,
                                        justifyContent: 'initial',
                                        px: 2.5,
                                        pr: "10px",
                                        pl: "10px"
                                    }}
                                    className={`list-group-item ${(
                                        checkIsActive(`/admin/client-page/business/documents/${clientId}?businessId=${business._id}`) || 
                                        checkIsActive2(`/admin/client-page/business/documents/${clientId}/${business._id}`)
                                    ) ? 'active' : ''}`}
                                >
                                    <ListItemIcon
                                        sx={{
                                            minWidth: 0,
                                            mr: 2,
                                            justifyContent: 'center',
                                        }}
                                    >
                                        <WorkIcon />
                                    </ListItemIcon>
                                    <ListItemText primary={business.businessName} sx={{ display: "block"}}/>
                                </ListItemButton>
                            </ListItem>
                        ))}
                     </List>
                </Collapse>
            )}
            <Divider />
            <ListItem sx={{ display: 'block' }}>
                <ListItemButton
                    component={Link} 
                    to={isClientPage ? `/admin/client-page/refunds/${clientId}` :  `/admin/merge/client-page/refunds/${clientId}`}
                    sx={{
                        minHeight: 48,
                        justifyContent: 'initial',
                        px: 2.5,
                        pr: "10px",
                        pl: "10px"
                    }}
                    className={`list-group-item ${checkIsActive(isClientPage ? `/admin/client-page/refunds/${clientId}` : `/admin/merge/client-page/refunds/${clientId}`) ? 'active' : ''}`}
                >
                    <ListItemIcon
                        sx={{
                            minWidth: 0,
                            mr: 2,
                            justifyContent: 'center',
                        }}
                    >
                       <PaidIcon />
                    </ListItemIcon>
                    <Tooltip title="Refunds/Amounts Due" placement="right-start">
                        <ListItemText primary="Refunds/Amounts Due" sx={{ display: "block"}}/>
                    </Tooltip>
                </ListItemButton>
            </ListItem>
            
        </List>


        </>
    )

}

export default DesktopDrawer;