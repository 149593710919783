import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import EditIcon from '@mui/icons-material/Edit';
import BorderColorIcon from '@mui/icons-material/BorderColor';


const BusinessSummary = ({ business }) => {
    const [editMode, setEditMode] = useState(false);
    const [userPermissions, setUserPermissions] = useState(
        business.users.map(user => ({
          ...user,
          viewPermission: user.permissions === 'view',
        }))
    );
    const [users, setUsers] = useState([])

    useEffect(() => {
        if(business && business.users) {
            setUsers(business.users)
        }
    }, [business]) 


    const handleCheckboxChange = (index) => {
        const updated = [...userPermissions];
        updated[index].viewPermission = !updated[index].viewPermission;
        setUserPermissions(updated);
    };

    const handleSubmit = () => {
        const updatedPermissions = userPermissions.map(user => ({
            userId: user.user,
            permissions: user.viewPermission ? 'view' : 'none'
        }));
        onSubmitPermissions(updatedPermissions);
        setEditMode(false);
    };

    return (
        
        <>
            {business && users ? (
                <Box className="business-summary-wrapper">
                    <Box className="status-section">
                        <Box className="status-details">
                        <Typography>Status: <span className="status-badge dissolved">Dissolved</span></Typography>
                        <Typography>Dissolution Date: <strong>{business.dissolvedDate || 'Not Available'}</strong></Typography>
                        <Typography>Dissolution Date: <strong>{business.ein || 'Not Available'}</strong></Typography>
                        </Box>
                    </Box>

                    {business.users?.length > 0 && (
                        <Box className="users-section">
                            <Box className="users-header">
                                <Typography variant="h6" className="section-title">Frozen Users</Typography>
                                <IconButton size="small" onClick={() => setEditMode(prev => !prev)}>
                                    <BorderColorIcon />
                                </IconButton>
                            </Box>

                            <Grid container spacing={2}>
                                {users.map((user, index) => (
                                    <Grid item xs={12} sm={12} md={12} key={user.user}>
                                        <Paper elevation={1} className="user-card">
                                        <Typography className="user-name">{user.firstName} {user.lastName}</Typography>
                                        <FormControlLabel
                                            control={
                                            <Checkbox
                                                checked={user.permissions === 'view'}
                                                onChange={() => handleCheckboxChange(index)}
                                                disabled={!editMode}
                                            />
                                            }
                                            label="View"
                                            className="user-checkbox"
                                        />
                                        </Paper>
                                    </Grid>
                                ))}
                            </Grid>

                            {editMode && (
                                <Box display="flex" justifyContent="flex-end" mt={3}>
                                    <Button className='save-business-btn' variant="contained" color="primary" onClick={handleSubmit}>
                                        Cancel Changes
                                    </Button>
                                    <Button className='save-business-btn' variant="contained" color="primary" onClick={handleSubmit}>
                                        Save Changes
                                    </Button>
                                </Box>
                            )}
                        </Box>
                    )}
                </Box>
            ): null}
        </>
    );
}

export default BusinessSummary;