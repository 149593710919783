import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import GeneralQuestionnaireView from "../../general-client-components/general-questionnaire-view";
import Invite from "../../general/Invite";
import PersonalDocuments from "./MyPersonalDocuments";
import PersonalFiles from "./MyPersonalDocuments/PersonalFiles.js"
import BusinessDocuments from "./MyBusinessDocuments";
import BusinessFiles from './MyBusinessDocuments/BusinessFiles.js';
import ClientInfo from "./ClientInfo";
import GeneralClientRefunds from "../../general-client-components/general-client-refunds";
import ClientDashboard from './index.js';


const ClientRoutes = () => {
    return (
        <Routes>
            <Route path="/" element={<ClientDashboard />}>
                <Route index element={<Navigate replace to="my-account" />} />
                <Route path="my-account" element={<ClientInfo />} />
                <Route path="history" element={<GeneralQuestionnaireView />} />
                <Route path="documents/personal" element={<PersonalDocuments />} />
                <Route path="documents/personal/folder/:folderName/year/:year" element={<PersonalFiles />} />
                <Route path="documents/business/:businessId" element={<BusinessDocuments />} />
                <Route path="documents/business/:businessId/folder/:folderName/year/:year" element={<BusinessFiles />} />
                <Route path="refunds" element={<GeneralClientRefunds />} />
                <Route path="invite" element={<Invite />} />
            </Route>
        </Routes>
    );
};
  
export default ClientRoutes;