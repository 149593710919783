import React, {useEffect, useState} from 'react';
import { useSelector } from 'react-redux';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import FolderSpecialIcon from '@mui/icons-material/FolderSpecial';
import DeleteIcon from '@mui/icons-material/Delete';

import FolderIcon from '../../../assets/icons/folder-taxbox-cropped.png';

import EmptyDataComponent from '../../ui/EmptyDataComponent';

import { useFetchData } from '../../../hooks/useFetchData';

import AddFolders from './AddFolders.js';
import EditFolder from './EditFolder';
import LoadingGrid from '../../ui/Loading';

const AdminFolders = () => {

    const { userToken } = useSelector((state) => state.user);
    const [childChanges, setChildChanges] = useState(false);
    const [loading, data, error] = useFetchData('/api/admin/folders', userToken, childChanges);
    const [folders, setFolders] = useState([]);
    const [open, setOpen] = useState(false);
    const [isEditOpen, setEditOpen] = useState(false);
    const [currentFolder, setCurrentFolder] = useState(null);
	

    useEffect(() => {
        if(data) {
            setFolders(data)
        }
    }, [data])

    const handleDialogOpen = () => {
       setOpen(true)
    }

    const handleEditOpen = (folder) => {
        setEditOpen(true);
        setCurrentFolder(folder)
    }

    return (
        <Box id="admin-folders">
            <Grid container justifyContent="space-between" alignItems="center" mb={5}>
                <Grid item>
                    <Typography component="h1">
                        Folders
                    </Typography>
                </Grid>
                <Grid item>
                    <Button className="add-folders-btn" onClick={handleDialogOpen}>Add New</Button>
                </Grid>
            </Grid>
            <Grid container spacing={3}>
            {loading && <LoadingGrid />}
            {error && <Typography color="error">Something went wrong. Could not get data from the server.</Typography>}
            {folders && folders.length > 0 && (
                <Grid item xs={12} container spacing={3}>
                    {folders.map((folder, index) => (
                        <Grid item xs={12} sm={6} md={4} lg={3} className='folder-item' key={index}>
                            <Box className="folder-box" p={2} border={1} borderRadius={2}>
                                <Box display="flex" justifyContent="space-between" alignItems="start" mb={1}>
                                    <img src={FolderIcon} alt="Folder Icon" className="folder-icon" />
                                    <Box>
                                        {folder.canBeRenamed && (
                                            <IconButton onClick={() => handleEditOpen(folder)}>
                                                <EditIcon />
                                            </IconButton>
                                        )}
                                        {folder.canBeDeleted && (
                                            <IconButton onClick={() => handleDelete(folder._id)}>
                                                <DeleteIcon />
                                            </IconButton>
                                        )}
                                    </Box>
                                </Box>
                                <Box>
                                    <Typography variant="h6" component="h6" className="folder-name">{folder.name}</Typography>
                                    <Typography variant="body2" component="p" color="textSecondary">{folder.access}</Typography>
                                </Box>
                            </Box>
                        </Grid>
                    ))}
                </Grid>
            )} 
            {!loading && folders && folders.length === 0 && (
                <Grid item xs={12}>
                    <EmptyDataComponent msg="It looks like you haven't created any folders yet." />
                </Grid>
            )}
        </Grid>
            <AddFolders
                open={open}
                setOpen={setOpen}
                userToken={userToken}
                setFolders={setFolders}
                folders={folders}
                setChildChanges={setChildChanges}
            />
            <EditFolder 
                open={isEditOpen}
                currentFolder={currentFolder}
                setEditOpen={setEditOpen}
                userToken={userToken}
                setChildChanges={setChildChanges}
            />
        </Box>
    )

}

export default AdminFolders;