import React, { useEffect, useState, useRef } from 'react';
import { styled } from '@mui/material/styles';
import { useDispatch } from 'react-redux';
import axios from 'axios';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import FormLabel from '@mui/material/FormLabel';
import Button from '@mui/material/Button';
import Switch, { SwitchProps } from '@mui/material/Switch';
import Alert from '@mui/material/Alert';
import CircularProgress from '@mui/material/CircularProgress';
import ReplayIcon from '@mui/icons-material/Replay';

import EmptyDataComponent from '../../../../ui/EmptyDataComponent';
import DialogSuccessMsg from '../../../../ui/DialogSuccessMsg';

const Android12Switch = styled(Switch)(({ theme }) => ({
    padding: 8,
    '& .MuiSwitch-track': {
      borderRadius: 22 / 2,
      '&::before, &::after': {
        content: '""',
        position: 'absolute',
        top: '50%',
        transform: 'translateY(-50%)',
        width: 16,
        height: 16,
      },
      '&::before': {
        backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
          theme.palette.getContrastText(theme.palette.primary.main),
        )}" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z"/></svg>')`,
        left: 12,
      },
      '&::after': {
        backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
          theme.palette.getContrastText(theme.palette.primary.main),
        )}" d="M19,13H5V11H19V13Z" /></svg>')`,
        right: 12,
      },
    },
    '& .MuiSwitch-thumb': {
      boxShadow: 'none',
      width: 16,
      height: 16,
      margin: 2,
    },
}));



const FrozenUsers = ({ frozenCurrentUsers, activeCurrentUsers, businessId, userToken, setChildChanges, isSubmitted, setSubmitted }) => {

    const [frozenUsers, setFrozenUsers] = useState([]);
    const [activeUsers, setActiveUsers] = useState([]);
    const [isSubmitLoading, setSubmitLoading] = useState(false);
    const [canUnfreezeMore, setCanUnfreezeMore] = useState(true);
    const [submitError, setSubmitError] = useState(null);
    // const [isSubmitted, setSubmitted] = useState(false)


    const correctPassword = "12345";

    useEffect(() => {
        if (frozenCurrentUsers) {
            const updatedUsers = frozenCurrentUsers.map(user => ({
                ...user,
                newPermission: user.permissions,
                unfreeze: false,
            }));
    
            setFrozenUsers(updatedUsers);
        }
    }, [frozenCurrentUsers]);

    useEffect(() => {
        // Calculate the total number of active users and users with unfreeze: true
        const unfreezingUsersCount = frozenUsers.filter(user => user.unfreeze).length;
        const totalUsers = activeUsers.length + unfreezingUsersCount;
        // console.log(totalUsers)
    
        // Set the state based on whether the limit is reached
        setCanUnfreezeMore(totalUsers <= 3);
    }, [activeUsers, frozenUsers]);


    useEffect(() => {
        if(activeCurrentUsers) {
            setActiveUsers(activeCurrentUsers)
        }
    }, [activeCurrentUsers])

    const handlePermissionChange = (userId, permission) => {
        setFrozenUsers(prevUsers =>
            prevUsers.map(user => {
                if (user.user === userId) {
                    if (permission === 'view') {
                        // Toggle "view" permission
                        return {
                            ...user,
                            newPermission: user.newPermission === 'view' ? 'none' : 'view',
                        };
                    }
    
                    if (permission === 'upload') {
                        // Toggle "upload" permission
                        return {
                            ...user,
                            newPermission: user.newPermission === 'upload' ? 'view' : 'upload',
                        };
                    }
                }
                return user; // No changes if the user ID doesn't match
            })
        );
    };

    const handlePasswordChange = (userId, value) => {
        setFrozenUsers(prevUsers =>
            prevUsers.map(user =>
                user.user === userId
                    ? {
                          ...user,
                          password: value,
                          newPermission: value === correctPassword ? 'view' : user.newPermission || 'none',
                          unfreeze: value === correctPassword,
                      }
                    : user
            )
        );
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        setSubmitLoading(true);
        try {
            const config = {
                headers: {
                    Authorization: `Bearer ${userToken}`,
                },
            };

            const response = await axios.put(
                `/api/admin/businesses/${businessId}/settings/unfreeze-users`,
                frozenUsers,
                config
            );
            if(response.data.success) {
                setSubmitted(true);
                setSubmitError('');
                setSubmitLoading(false);
            } else {
                setSubmitError(error.response.data.error || "Something went wrong. Please refresh the page or contact support if the issue persists.");
            }
            
        } catch (error) {
            console.log(error)
            setSubmitError(error.response.data.error || "Something went wrong. Please refresh the page or contact support if the issue persists.");
        } finally {
            setSubmitLoading(false);
        }
    };

    const handleReload = (e) => {
        e.stopPropagation();
        setChildChanges((prevChanges) => !prevChanges);
        setSubmitted(false);
    }

    if (isSubmitted) {
        return (
            <Box>
                <DialogSuccessMsg msg="Business information updated successfully." />
                <Grid container justifyContent="center">
                    <Grid item>
                        <Button 
                            variant="outlined" 
                            startIcon={<ReplayIcon />}
                            onClick={handleReload}
                        >
                            Reload
                        </Button>
                    </Grid>
                </Grid>
            </Box>
        )
    }
    

    return (
        <Box>
            {frozenUsers ? (
                <Box className='active-users' mb={5}>
                    <Grid container className='heading-container' justifyContent="space-between" alignItems="center">
                        <Grid item>
                            <Typography className='heading' component="p">Frozen clients</Typography>
                        </Grid>
                    </Grid>
                    {!canUnfreezeMore && (
                        <Alert severity="warning" className='business-alert'>
                            You’ve reached the limit! The total number of active and unfrozen users can’t exceed 3.
                        </Alert>
                    )}
                    {submitError && (
                        <Alert severity="error" onClose={() => setSubmitError(null)}  className='business-alert'>
                            {submitError}
                        </Alert>
                    )}
                    {frozenUsers.length > 0 ? (
                        <Grid container spacing={2}>
                            {frozenUsers.map((user, index) => (
                                <Grid item container xs={12} alignItems="center" spacing={2} key={user.user} >
                                    <Grid item flex="1">
                                        <Typography>{user.firstName} {user.lastName}</Typography>
                                    </Grid>
                                    <Grid item>
                                        <FormControlLabel
                                            control={
                                                <Checkbox 
                                                checked={user.newPermission === 'view' || user.newPermission === 'upload'}
                                                onChange={() => handlePermissionChange(user.user, 'view')}
                                                disabled={user.password === correctPassword}
                                                />
                                            }
                                            label="View"
                                        />
                                    </Grid>
                                    <Grid item>
                                        <FormControlLabel
                                            control={
                                                <Checkbox 
                                                    checked={user.newPermission === 'upload'}
                                                    onChange={() => handlePermissionChange(user.user, 'upload')}
                                                    disabled={user.password !== correctPassword} // Can only check "Upload" if password is entered
                                                />
                                            }
                                        label="Upload"
                                        />
                                    </Grid>
                                    <Grid item>
                                        <TextField 
                                            size='small'
                                            label="Password" 
                                            name="password"
                                            value={user.password || ''}
                                            disabled={!canUnfreezeMore && !user.unfreeze}
                                            onChange={(e) => handlePasswordChange(user.user, e.target.value)}
                                        />
                                    </Grid>
                                    <Grid item>
                                        <FormLabel>Freeze</FormLabel>
                                        <Android12Switch
                                            name="freeze"
                                            disabled={true}
                                            checked={user.password !== correctPassword}
                                            onChange={() => handleFreezeToggle(user.user)}
                                        />
                                    </Grid>
                                </Grid>
                            ))}
                            <Grid item xs={12} container justifyContent="flex-end">
                                <Grid item>
                                    <Button 
                                        className='save-business-btn' 
                                        type="submit" 
                                        disabled={isSubmitLoading}
                                        onClick={handleSubmit}
                                    >
                                        {isSubmitLoading ? (
                                            <>
                                                Submit&nbsp;
                                                <CircularProgress size={16} color="inherit" />
                                            </>
                                        ) : (
                                            'Submit'
                                        )}
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    ) : (
                        <EmptyDataComponent msg="No frozen clients found. This section will display any clients marked as frozen when they become available." />
                    )}
                </Box>
            ) : (
               <Typography>Hello</Typography>
            )}

        </Box>
    )
}




export default FrozenUsers;
