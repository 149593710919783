import React, {useEffect, useState} from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import Container from '@mui/material/Container';
import Dialog from '@mui/material/Dialog';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid'
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import Chip from '@mui/material/Chip';
import CircularProgress from '@mui/material/CircularProgress';
import Alert from '@mui/material/Alert';

import ModalFileUpload from './ModalUploadFiles';
import DialogSuccessMsg from '../../../ui/DialogSuccessMsg';


import { capitalize } from '@mui/material';



const AddNewFiles = ({ open, setOpen, folders, businessId, businessName, setChildChanges, userType }) => {

    const [newUserFiles, setNewUserFiles] = useState({
    	userFiles: [],
		taxYear: '',
		folder: {folderName: '', folderId: ''},
		userType: userType
  	});
	const { userToken } = useSelector((state) => state.user);
	const { clientId } = useParams();
    const [errorMsg, setErrorMsg] = useState('');
	const [isUploaded, setIsUploaded] = useState(false);
	const [yearError, setYearError] = useState('');
	const [loading, setLoading] = useState(false)

    const updateUploadedFiles = (files) => {
        setNewUserFiles({ ...newUserFiles, userFiles: files });
    }

    const handleClose = () => {
		if(isUploaded) {
			setChildChanges((prevChanges) => !prevChanges);
		}
        setOpen(false);
		setIsUploaded(false);
		setErrorMsg('');
		setNewUserFiles({
			userFiles: [],
			taxYear: '',
			folder: {folderName: '', folderId: ''},
			userType: userType
		})
		setYearError('');
		setLoading(false);
    }

	const handleInputChange = (e) => {
		setErrorMsg('')
		const { name, value } = e.target;
	
		if (name === "taxYear" && !/^\d{0,4}$/.test(value)) {
			return; // Prevent updating the state if the value is invalid
		}
	
		if (name === "folder") {
			const folderId = value;
			const selectedFolder = folders.find((folder) => folder.folderId === folderId);
	
			setNewUserFiles((prevState) => ({
				...prevState,
				folder: {
					folderName: selectedFolder ? selectedFolder.folderName : '',
					folderId: selectedFolder ? selectedFolder.folderId : ''
				}
			}));
		} else {
			// Handle other input changes
			setNewUserFiles((prevState) => ({
				...prevState,
				[name]: value
			}));
		}
	};

    const handleOnSubmit = async (e) => {
    	e.preventDefault();

		if (newUserFiles.taxYear === '') {
			setErrorMsg('The year field is required. Please enter a valid year.');
			return;  
		} else if (newUserFiles.taxYear && !/^\d{0,4}$/.test(newUserFiles.taxYear)) {
			setErrorMsg('The year must be a valid 4-digit number.');
			return;  
		}

		if (newUserFiles.userFiles.length === 0) {
			setErrorMsg('No files selected. Please select at least one file to upload.');
			return;  
		}

		if (!newUserFiles.folder.folderId) {
			setErrorMsg('A folder must be selected. Please choose a valid folder.');
			return;
		}

		setLoading(true);

    	try {
			const formData = new FormData();
			for (let i = 0; i < newUserFiles.userFiles.length; i++) {
				formData.append("files", newUserFiles.userFiles[i]);
			}
			formData.append('taxYear', newUserFiles.taxYear);
			formData.append('folderName', newUserFiles.folder.folderName);
			formData.append('folderId', newUserFiles.folder.folderId);
			if(userType) {
				formData.append('userType', newUserFiles.userType);
			}

			let url;

			if (userType === 'business' && businessId ) {
				url = `/api/client-page/files/upload/business/${clientId}/${businessId}`
			} else if (userType === 'personal') {
				url = `/api/client-page/files/upload/personal/${clientId}`
			} else if (userType === 'merged') {
				url = `/api/client-page/files/upload/m/merged/${clientId}`
			} else {
				setErrorMsg('Something went wrong. Please refresh the page or contact support if the issue persists.');
				return;
			}
			
			const response = await axios.post(url, formData, {
				headers: {
					Authorization: `Bearer ${userToken}`,
					'Content-Type': 'multipart/form-data'
				}
			});

			if(response.data.success) {
                setIsUploaded(true);
				setErrorMsg('');
				setLoading(false);
            } else {
				setIsUploaded(false);
                setErrorMsg(error.response.data.error || "Something went wrong. Please refresh the page or contact support if the issue persists.");
            }

		} catch (error) {
			console.log(error);
			setErrorMsg('Something went wrong. Please refresh the page or contact support if the issue persists.')
		} finally {
			setLoading(false);
		}
  	}

    return (
        <>
            <Dialog
        	    aria-labelledby="upload-documents-modal"
        	    aria-describedby="upload-documents-modal"
        	    open={open}
			    onClose={handleClose}
                fullWidth
				id="admin-documents-dialog"
                maxWidth="md"
      	    >
				<Container sx={{ m: 0, p: 2 }} style={{ overflow: 'auto' }}>
					<Grid container justifyContent="space-between" alignItems="center" className="upload-dialog-header">
						<Grid item>
							<Chip color="warning" label={businessName ? `${businessName} File Upload` : 'Personal File Upload'} />
						</Grid>
						<Grid item>
							<IconButton onClick={handleClose}>
								<CloseIcon />
							</IconButton>
						</Grid>
					</Grid>
					{errorMsg && (
						<Grid container mt={3}>
							<Grid item xs={12}>
								<Alert severity="error" onClose={() => setErrorMsg(null)}  className='business-alert'>
						 			{errorMsg}
					 			</Alert>
							</Grid>
						</Grid>
					)}
					{isUploaded ? (
						<Box className="admin-doc-modal">
							<DialogSuccessMsg msg="Files uploaded successfully" />
						</Box>
					) : (
						<Box mt={4} mb={5} className="admin-doc-modal" component="form" onSubmit={handleOnSubmit}>
							<ModalFileUpload label="Documents Upload" multiple updateFilesCb={updateUploadedFiles} />
							<Grid container justifyContent="start" className='additional-fields' mt={1} spacing={2}>
								<Grid item xs={6}>
									<TextField 
										fullWidth 
										label="Year" 
										size="small" 
										name='taxYear'
										value={newUserFiles.taxYear}
										onChange={handleInputChange}
										error={!!yearError}
    									helperText={yearError}
										inputProps={{
											maxLength: 4,
											pattern: "\\d{4}"
										}}
									/>
								</Grid>
							</Grid>
							<Grid container justifyContent="start" className='additional-fields' mt={1} spacing={2}>
								{folders && folders.length > 0 ? (
									<Grid item xs={6}>
										<TextField 
											select 
											name="folder" 
											label="Choose Folder" 
											fullWidth 
											size="small"
											value={newUserFiles.folder.folderId}
											onChange={handleInputChange}
											required
										>
											{folders.map((item) => (
												<MenuItem value={item.folderId} key={item.folderId}>
														{capitalize(item.folderName)}
												</MenuItem>
											))}
										</TextField>
									</Grid> 
							) : null} 
							</Grid>
							<Grid container justifyContent="flex-end" sx={{width: '95%'}}>
								<Grid item xs={2}>
									<Button 
										className='add-files-btn' 
										type="submit"
										disabled={yearError || loading}
									> 
										{loading ? (
                                            <>
                                                Submit&nbsp;
                                                <CircularProgress size={18} color="inherit" />
                                            </>
                                        ) : (
                                            'Submit'
                                        )}
									</Button>
								</Grid>
							</Grid>
						</Box>
					)}
				</Container>
      		</Dialog>
        </>
    )
}

export default AddNewFiles;