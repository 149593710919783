import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import AddIcon from '@mui/icons-material/Add';
import CircularProgress from '@mui/material/CircularProgress';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';


import { useFetchData } from '../../../../../hooks/useFetchData';
import { setRef } from '@mui/material';

const NewUsers = ({ userToken, businessId, newUsers, setNewUsers, activeUsers, isEditingActiveUsers, setSubmitted }) => {

    const [loading, data, error] = useFetchData(`/api/admin/business/clients/${businessId}`, userToken);
    const [clients, setClients] = useState([]);
    const [submitLoading, setSubmitLoading] = useState(false);
    const [submitError, setSubmitError] = useState(null);
    // const [isSubmitted, setSubmitted] = useState(false)

    useEffect(() => {
        if(data) {
            setClients(data)
        }
    }, [data]);

    useEffect(() => {
        if(newUsers) {
            console.log("New", newUsers)
        }
    }, [newUsers]);

    useEffect(() => {
        if(activeUsers) {
            console.log("Active", activeUsers)
        }
    }, [activeUsers]);

    useEffect(() => {
        setSubmitError(null)
    }, [isEditingActiveUsers])

    const options = clients.length > 0 ? clients.map((option) => {
        const firstLetter = option.firstName[0].toUpperCase();
        return {
            firstLetter: /[0-9]/.test(firstLetter) ? '0-9' : firstLetter,
            ...option
        };
    }) : [];

    const handleClientChange = (index, value) => {
        setNewUsers(prevUsers => {
            const updatedUsers = [...prevUsers];
            if (value) {
                // Update the selected user for the given index
                updatedUsers[index] = { 
                    user: value.id, 
                    permissions: 'view', 
                    firstName: value.firstName, 
                    lastName: value.lastName 
                };
            } else {
                // Clear the selected user but keep the field
                updatedUsers[index] = { 
                    user: null, 
                    permissions: 'view', 
                    firstName: '', 
                    lastName: '' 
                };
            }
            return updatedUsers;
        });
    };

    const handleCheckboxChange = (userId, newPermission) => {
        setNewUsers(prevUsers => {
            return prevUsers.map(user => {
                if (user.user === userId) {
                     // If "upload" is already checked and it's clicked again, set permissions to "view"
                    if(newPermission === 'upload' && user.permissions === 'upload') {
                        return {
                            ...user,
                            permissions: 'view'
                        };
                    } else {
                        return {
                            ...user,
                            permissions: newPermission
                        };
                    }   
                }
                return user; // Return the user unchanged if it's not the one we're looking for
            });
        });
    };

    const isOptionEqualToValue = (option, value) => option.id === value?.user;

    const getOptionDisabled = (option) => {
        return newUsers.some(client => client?.user === option.id);
    };

    const handleAddField = () => {
        if (newUsers.length + activeUsers.length < 3) {
            setNewUsers(prevUsers => [...prevUsers, { user: null, permissions: 'view', firstName: '', lastName: '' }]);
        }
    };

    const handleRemoveField = (index) => {
        setNewUsers(prevUsers => {
            const updatedUsers = [...prevUsers];
            updatedUsers.splice(index, 1);
            return updatedUsers;
        });
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        setSubmitLoading(true);
        try {
            const config = {
                headers: {
                    Authorization: `Bearer ${userToken}`,
                },
            };

            const response = await axios.put(
                `/api/admin/businesses/${businessId}/settings/add-users`,
                newUsers,
                config
            );
            console.log(response.data.error)
            if (response.data.error) {
                setSubmitError(response.data.error);
            } else {
                setSubmitted(true);
                setSubmitError('');
                // setChildChanges((prevChanges) => !prevChanges);
            }
        } catch (error) {
            console.log(error)
            setSubmitError(error.response.data.error);
        } finally {
            setSubmitLoading(false);
        }
    };

    return (
        <>
        {submitError && (
            <Alert severity="error"  onClose={() => setSubmitError(null)} className='business-alert'>
                {submitError}
            </Alert>
        )}
        {newUsers.map((newUser, index) => (
            <Box key={index} mb={2}>
                <Grid container>
                    <Grid item>
                        <Autocomplete 
                            options={options.sort((a, b) => -b.firstLetter.localeCompare(a.firstLetter))}
                            groupBy={(option) => option.firstLetter}
                            getOptionLabel={(option) => `${option.firstName} ${option.lastName}`}
                            sx={{ width: 400 }}
                            size="small"
                            value={newUsers[index]?.user ? newUsers[index] : null}
                            onClick={(e) => e.stopPropagation()}
                            onChange={(e, value) => {e.stopPropagation(); handleClientChange(index, value); }}
                            isOptionEqualToValue={isOptionEqualToValue}
                            getOptionDisabled={getOptionDisabled}
                            renderInput={(params) => <TextField {...params} label="Choose client" fullWidth />}
                            // disabled={activeUsers.length + newUsers.length >= 3}
                        />
                    </Grid>
                    {index > 0 && (
                        <Grid item>
                            <IconButton
                                onClick={handleRemoveField}
                            >
                                <RemoveCircleOutlineIcon />
                            </IconButton>
                        </Grid>
                    )}
                </Grid>
               
                {newUsers[index] && (
                    <Grid container alignItems="center" spacing={2}>
                        {/* <Grid item>
                            <Typography>{newUsers[index].firstName}</Typography>
                        </Grid>
                        <Grid item>
                            <Typography>{newUsers[index]._id}</Typography>
                        </Grid> */}
                        <Grid item>
                            <FormControlLabel
                                control={
                                    <Checkbox 
                                        checked={newUsers[index].permissions === 'view' || newUsers[index].permissions === 'upload'}
                                        onChange={(e) => handleCheckboxChange(newUsers[index].user, 'view')}
                                    />
                                }
                                label="View"
                            />
                        </Grid>
                        <Grid item>
                            <FormControlLabel
                                control={
                                    <Checkbox 
                                        checked={newUsers[index].permissions === 'upload'}
                                        onChange={(e) => handleCheckboxChange(newUsers[index].user, 'upload')}

                                    />
                                }
                                label="Upload"
                            />
                        </Grid>
                        {newUsers[index].user && (
                            <Grid item className='warning' xs={12}>
                                <Typography className='warning-msg'>
                                    {newUsers[index].firstName} {newUsers[index].lastName} will be granted access to this business and its associated documents.
                                </Typography>
                            </Grid>
                        )}
                    </Grid>
                )}

            </Box>

        ))}
        {activeUsers.length + newUsers.length < 3 && (
            <IconButton className='add-more-btn' onClick={handleAddField}>
                <AddIcon />
            </IconButton>
        )}
        <Grid xs={12} container justifyContent="flex-end">
            <Grid item>
                <Button 
                    className='save-business-btn' 
                    type="submit" 
                    disabled={submitLoading}
                    onClick={handleSubmit}
                >
                    {submitLoading ? (
                        <>
                            Submit&nbsp;
                            <CircularProgress size={16} color="inherit" />
                        </>
                    ) : (
                        'Submit'
                    )}
                </Button>
            </Grid>
        </Grid>
     
        </>
    )
}

export default NewUsers;