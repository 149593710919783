import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Grid';
import Grid from '@mui/material/Grid';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Avatar from '@mui/material/Avatar';
import Tooltip from '@mui/material/Tooltip';

const groups = ['All Questions', 'Personal Information', 'Personal Residence', 'Dependents', 'Work & Income Sources', 'Healthcare', 'Education', 
'Deductions and Credits', 'Investments', 'Retirement or Severance', 'Sale of Your Home', 'Gifts', 'Foreign Matters', 'Miscellaneous' ]

import {generateUniqueKey } from '../../../utils/generateKey';


const AllQuestionsView = ({ data, handleEditOpen, handleDeleteOpen }) => {


    return (

        <>
           
            {[...new Set(data.map(q => q.group))].map((group) => (
                <Box key={generateUniqueKey()} className="questionnaire-group">
                    <Typography variant="h6" className="questionnaire-group-title">
                    {group}
                    </Typography>

                    {data
                    .filter(question => question.group === group)
                    .sort((que1, que2) => que1.order - que2.order)
                    .map((question, index) => (
                        <Box className="questionnaire-item" key={generateUniqueKey()}>
                            <Grid container alignItems="center" spacing={2}>
                                <Grid item>
                                    <Avatar className="questionnaire-avatar">{question.order}</Avatar>
                                </Grid>

                                <Grid item xs className="questionnaire-question-text">
                                    <Typography>
                                        {question.text}
                                        <span className="question-type"> ({question.type === 'general' ? 'Yes/No' : 'Textfield'})</span>
                                    </Typography>
                                </Grid>

                                <Grid item>
                                    <Tooltip title="Edit">
                                        <IconButton onClick={() => handleEditOpen(question, index)}>
                                            <EditIcon color="primary" />
                                        </IconButton>
                                    </Tooltip>
                                    <Tooltip title="Delete">
                                        <IconButton onClick={() => handleDeleteOpen(question, index)}>
                                            <DeleteIcon color="error" />
                                        </IconButton>
                                    </Tooltip>
                                </Grid>
                            </Grid>

                            {/* Sub Questions */}
                            {question.subQuestion?.length > 0 && (
                                <Box className="subquestion-container">
                                    <Typography className="subquestion-title">Sub Questions:</Typography>
                                    {question.subQuestion.map((item, subIndex) => (
                                        <Typography key={generateUniqueKey()} className="subquestion-item">
                                            {subIndex + 1}. {item.subQuestionText}
                                            <span className="question-type"> ({item.subQuestionType === 'general' ? 'Yes/No' : 'Textfield'})</span>
                                        </Typography>
                                    ))}
                                </Box>
                            )}

                            {/* Notes */}
                            {question.note && (
                                <Box className="note-container">
                                    <Typography className="note-title">Note for Questionnaire:</Typography>
                                    <Typography className="note-text">{question.note}</Typography>
                                </Box>
                            )}

                            {question.noteForDocuments && (
                                <Box className="note-container note-docs">
                                    <Typography className="note-title">Note for Documents Upload:</Typography>
                                    <Typography className="note-text">{question.noteForDocuments}</Typography>
                                </Box>
                            )}
                        </Box>
                    ))}
                </Box>
            ))}

        </>
    );
};

export default AllQuestionsView;