import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import MergedClientDashboard from './index.js';
import MergedAccountInfo from './MergedAccountInfo/index.js';
import GeneralQuestionnaireView from '../../general-client-components/general-questionnaire-view/index.js';
import MergedAccountDocuments from './MergedAccountDocuments/index.js';
import MergedFiles from './MergedAccountDocuments/MergedFiles.js';
import GeneralClientRefunds from '../../general-client-components/general-client-refunds/index.js';


const MergedClientRoutes = () => {
    return (
        <Routes>
            <Route path="/" element={<MergedClientDashboard />}>
                <Route index element={<Navigate replace to="my-account" />} />
                <Route path="my-account/:mergedClientId" element={<MergedAccountInfo />} />
                <Route path="history/:mergedClientId" element={<GeneralQuestionnaireView />} />
                <Route path="documents/:mergedClientId" element={<MergedAccountDocuments />} />
                <Route path="documents/:mergedClientId/folder/:folderName/year/:year" element={<MergedFiles />} />
                <Route path="refunds/:mergedClientId" element={<GeneralClientRefunds />} />
                {/*<Route path="invite" element={<Invite />} /> */}
            </Route>
        </Routes>
    );
};
  
export default MergedClientRoutes;