import React, { useEffect, useState } from 'react';
import { useSelector } from "react-redux";
import { Link, useParams } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import Tooltip from '@mui/material/Tooltip';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { capitalize } from '@mui/material';

import { useFetchData } from '../../../../hooks/useFetchData';
import { useYearFilterForFolders } from '../../../../hooks/useYearFilterForFolders';

import FolderIcon from '../../../../assets/icons/folder-3.png';
import ErrorDataComponent from '../../../ui/ErrorDataComponent';
import LoadingGrid from '../../../ui/Loading';

import GeneralClientAddFiles from '../../../general-client-components/general-client-documents/general-client-add-files';


const MergedAccountDocuments = () => {
    
    const { mergedClientId } = useParams();
    const { userToken, userInfo } = useSelector((state) => state.user);
    const [childChanges, setChildChanges] = useState(false);
    const [loading, data, error] = useFetchData(`/api/c/dashboard/merged/documents-summary/${mergedClientId}`, userToken, childChanges);
    const [folders, setFolders] = useState([]);
    const [open, setOpen] = useState(false);
    const [isUploadButtonVisible, setUploadButtonVisible] = useState(true);

    const {
        years,
        selectedYear,
        handleSelectChange,
        filteredData,
    } = useYearFilterForFolders(data?.folderSummary);
    
    useEffect(() => {
        if(data && data.folderSummary) {
            const transformedFolders = data.folderSummary.map(item => ({
                folderName: item.folder,
                folderId: item.folderId
            }));

            setFolders(transformedFolders);
        }
    }, [data])


    useEffect(() => {
        if(data && data.folderSummary) {
            setUploadButtonVisible(data.isDocumentUploadActive)
        }
    }, [data])

    const handleAddDocs = () => {
        setOpen(true)
    }


    if(loading) {
        return <LoadingGrid gridNumber={4} />
    }

    if(error) {
        return (
            <>
                <Grid container id='folders-header' alignItems="center" justifyContent="space-between">
                    <Grid item xs={6}>
                        <Typography component="h1">Personal Documents</Typography>
                    </Grid>
                </Grid>
                <ErrorDataComponent msg={error?.data?.error || 'Something went wrong. Please reload the page or contact support if the issue persists.'} />
            </>
        )
    }

    return (
        <>
            <Grid container id='folders-header' alignItems="center" justifyContent="space-between">
                <Grid item xs={6}>
                    <Typography component="h1">Personal Documents</Typography>
                </Grid>
                <Grid item>
                    <FormControl sx={{width: '150px'}}>
                        <InputLabel id="year-select-label">Year</InputLabel>
                        <Select
                            labelId="year-select-label"
                            id="year-select"
                            size="small" 
                            value={selectedYear}
                            label="Year"
                            onChange={handleSelectChange}
                        >
                            {years.map(year => (
                                <MenuItem key={year} value={year}>
                                    {year}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
            </Grid>
            {isUploadButtonVisible ? (
                <Grid className='folders-btn-container' container alignItems="center" justifyContent="start" gap={2} mb={6}>
                    <Grid item>
                        <Button 
                            onClick={handleAddDocs}
                            startIcon={<CloudUploadIcon />}
                        >
                            Upload Files
                        </Button>
                    </Grid>
                </Grid>
            ) : (
                <Grid item className='inactive-account-msg' container alignItems="center" justifyContent="end">
                    <Typography component="span">
                        Inactive account
                    </Typography>
                    <Tooltip title="Documents cannot be uploaded here because the client currently has an active merged account.">
                        <HelpOutlineIcon />
                    </Tooltip>
                </Grid>
            )}
             <Box id="admin-folders-container">
                <Grid container justifyContent="center" spacing={3} style={{ justifyContent: 'flex-start' }}>
                    {/* <Grid container justifyContent="center" item xs={12} sm={6} md={4} lg={3}>
                        <Grid item>
                            <Link 
                                to={`/admin/client-page/documents/${clientId}/folder/questionnaire`}
                                style={{ textDecoration: 'none' }}
                            >
                                <Paper className="box">
                                    <img src={FolderIcon} alt="questionnaire-folder" />
                                    <Typography variant="subtitle1" align="center">
                                        Questionnaire
                                    </Typography>
                                    <Typography className='folder-access'>public</Typography>
                                </Paper>
                            </Link>
                        </Grid>
                    </Grid> */}
                    {filteredData.map((item, index) => (
                        <Grid container justifyContent="center" item key={index} xs={12} sm={6} md={4} lg={3}>
                            <Grid item>
                            <Link 
                                to={`/dashboard/merge/documents/${mergedClientId}/folder/${item.folder}/year/${selectedYear}`} 
                                style={{ textDecoration: 'none' }}
                            >
                                <Paper className="box">
                                    <img src={FolderIcon} alt={item.folder} />
                                    <Typography variant="subtitle1" align="center">
                                        {capitalize(item.folder)} ({item.totalFiles})
                                    </Typography>
                                    <Typography className='folder-access'>{item.access}</Typography>
                                </Paper>
                                </Link>
                            </Grid>
                        </Grid>
                    ))}
                </Grid>
            </Box>
            <GeneralClientAddFiles
                userToken={userToken}
                open={open} 
                setOpen={setOpen} 
                userType="merged"
                setChildChanges={setChildChanges}
                mergedClientId={mergedClientId}
            />
        </>
    )
}

export default MergedAccountDocuments;