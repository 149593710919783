import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import FormLabel from '@mui/material/FormLabel';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Alert from '@mui/material/Alert';
import CircularProgress from '@mui/material/CircularProgress';
import ReplayIcon from '@mui/icons-material/Replay';

import DialogSuccessMsg from '../../../../ui/DialogSuccessMsg';

const GeneralSettings = ({frozenCurrentUsers, activeCurrentUsers, businessId, userToken}) => {

    const [isActive, setIsActive] = useState(true);
    const [dissolvedDate, setDissolvedDate] = useState('');
    const [dateError, setDateError] = useState('');
    const [frozenUsers, setFrozenUsers] = useState([]);
    const [activeUsers, setActiveUsers] = useState([]);
    const [isSubmitLoading, setSubmitLoading] = useState(false);
    const [submitError, setSubmitError] = useState(null);
    const [isSubmitted, setSubmitted] = useState(false);
    

    useEffect(() => {
        if (activeCurrentUsers) {
            const updatedUsers = activeCurrentUsers.map(user => {
                if (user.permissions === "upload") {
                    return { ...user, permissions: "view" }; 
                }
                return user; 
            });
            setActiveUsers(updatedUsers);
        }
    }, [activeCurrentUsers]);

    useEffect(() => {
        if(frozenCurrentUsers) {
            setFrozenUsers(frozenCurrentUsers)
        }
    }, [frozenCurrentUsers])

    // useEffect(() => {
    //     console.log(activeUsers)
    // }, [activeUsers])

    // useEffect(() => {
    //     console.log(frozenUsers)
    // }, [frozenUsers])

    const handleInputChange = (e) => {
        const input = e.target.value;
        // Remove any non-digit characters
        const digitsOnly = input.replace(/\D/g, '');

        // Format the string as MM/DD/YYYY
        let formattedDate = '';
        if (digitsOnly.length > 0) {
            formattedDate += digitsOnly.substring(0, 2); // MM
        }
        if (digitsOnly.length > 2) {
            formattedDate += '/' + digitsOnly.substring(2, 4); // DD
        }
        if (digitsOnly.length > 4) {
            formattedDate += '/' + digitsOnly.substring(4, 8); // YYYY
        }

        setDissolvedDate(formattedDate);

        // Validate input
        validateDate(formattedDate);
    };

    const validateDate = (date) => {
        setDateError(''); // Reset error initially

        const [month, day] = date.split('/');

        if (month && parseInt(month) > 12) {
            setDateError('Month cannot be greater than 12');
        } else if (day && parseInt(day) > 31) {
            setDateError('Day cannot be greater than 31');
        }
    };

    const handleActiveChange = (isActiveValue) => {
        setIsActive(isActiveValue); 
        if (isActiveValue) {
            setDissolvedDate(''); 
            setDateError(''); 
        }
    };

    const handleActiveUserChange = (userId, isChecked) => {
        setActiveUsers(prevUsers =>
            prevUsers.map(user =>
                user.user === userId
                    ? { ...user, permissions: isChecked ? 'view' : 'none' } // Set "view" if checked, "none" if unchecked
                    : user
            )
        );
    };
    
    const handleFrozenUserChange = (userId, isChecked) => {
        setFrozenUsers(prevUsers =>
            prevUsers.map(user =>
                user.user === userId
                    ? { ...user, permissions: isChecked ? 'view' : 'none' } // Set "view" if checked, "none" if unchecked
                    : user
            )
        );
    };

    const handleSubmit = async (event) => {

        event.preventDefault();

        if (!dissolvedDate || dateError) {
            setSubmitError("Please provide correct Dissolution Date");
            return;
        }

        setSubmitLoading(true);

        try {
            const config = {
                headers: {
                    Authorization: `Bearer ${userToken}`,
                },
            };

            const data = {
                dissolvedDate: dissolvedDate,
                activeUsers: activeUsers,
                frozenUsers: frozenUsers,
            }

            const response = await axios.put(
                `/api/admin/businesses/${businessId}/settings/dissolve-business`,
                data,
                config
            );
            if(response.data.success) {
                setSubmitted(true);
                setSubmitError('');
                setSubmitLoading(false);
            } else {
                setSubmitError(error.response.data.error || "Something went wrong. Please refresh the page or contact support if the issue persists.");
            }
        } catch (error) {
            console.log(error)
            setSubmitError(error.response.data.error || "Something went wrong. Please refresh the page or contact support if the issue persists.");
        } finally {
            setLoading(false);
        }
    };

    const handleReload = (e) => {
        e.stopPropagation();
        // setChildChanges((prevChanges) => !prevChanges);
        setSubmitted(false);
    }

    if (isSubmitted) {
        return (
            <Box>
                <DialogSuccessMsg msg="Business was dissolved successfully." />
                <Grid container justifyContent="center">
                    <Grid item>
                        <Button 
                            variant="outlined" 
                            startIcon={<ReplayIcon />}
                            onClick={handleReload}
                        >
                            Reload
                        </Button>
                    </Grid>
                </Grid>
            </Box>
        )
    }




    return (
        <Box className='active-users'>
            {submitError && (
                <Alert severity="error" onClose={() => setSubmitError(null)}  className='business-alert'>
                    {submitError}
                </Alert>
            )}
            <FormControl>
                <FormLabel>Is the business active?</FormLabel>
                <Box display="flex" alignItems="center" gap={2}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={isActive}
                                onChange={() => handleActiveChange(true)}
                            />
                        }
                        label="Yes"
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={!isActive}
                                onChange={() => handleActiveChange(false)}
                            />
                        }
                        label="No"
                    />
                </Box>
            </FormControl>
            
            {!isActive && (
                <>
                    <Grid container mt={4}>
                        <Grid item>
                            <FormLabel>Dissolution Date</FormLabel>
                            <Box>
                                <TextField 
                                    size="small"
                                    label="MM/DD/YYYY" 
                                    name="dissolvedDate"
                                    value={dissolvedDate}
                                    onChange={handleInputChange}
                                    inputProps={{ maxLength: 10 }}
                                    error={!!dateError}
                                    helperText={dateError}
                                    required
                                />
                            </Box>
                        </Grid>
                    </Grid>

                    <Grid container className='heading-container' justifyContent="space-between" alignItems="center" mt={4}>
                        <Grid item>
                            <Typography className='heading' component="p">Active clients</Typography>
                        </Grid>
                    </Grid>
                    {activeUsers ? (
                        <>
                            <Box>
                                {activeUsers.length > 0 ? (
                                    <Grid container spacing={2}>
                                        {activeUsers.map((user, index) => (
                                            <Grid item container xs={12} alignItems="center" spacing={2} key={user.user} >
                                                <Grid item flex="1">
                                                    <Typography>{user.firstName} {user.lastName}</Typography>
                                                </Grid>
                                                <Grid item>
                                                    <FormControlLabel
                                                        control={
                                                            <Checkbox 
                                                                checked={user.permissions === 'view'}
                                                                onChange={(e) => handleActiveUserChange(user.user, e.target.checked)}
                                                            />
                                                        }
                                                        label="View"
                                                    />
                                                </Grid>
                                            </Grid>
                                        ))}
                                    </Grid>
                                ) : (
                                    <Alert severity="error"  className='business-alert'>
                                        No active clients found. Please ensure clients are active or contact support for assistance.
                                    </Alert>
                                )}
                            </Box>
                        </>
                    ) : (
                        <Alert severity="error"  className='business-alert'>
                            Unable to retrieve client data. Please try refreshing the page or contact support for help.
                        </Alert>
                    )}

                    <Grid container className='heading-container' justifyContent="space-between" alignItems="center" mt={4}>
                        <Grid item>
                            <Typography className='heading' component="p">Frozen clients</Typography>
                        </Grid>
                    </Grid>
                    {frozenUsers ? (
                        <>
                            <Box>
                                {frozenUsers.length > 0 ? (
                                    <Grid container spacing={2}>
                                        {frozenUsers.map((user, index) => (
                                            <Grid item container xs={12} alignItems="center" spacing={2} key={user.user} >
                                                <Grid item flex="1">
                                                    <Typography>{user.firstName} {user.lastName}</Typography>
                                                </Grid>
                                                <Grid item>
                                                    <FormControlLabel
                                                        control={
                                                            <Checkbox 
                                                                checked={user.permissions === 'view'}
                                                                onChange={(e) => handleFrozenUserChange(user.user, e.target.checked)}
                                                            />
                                                        }
                                                        label="View"
                                                    />
                                                </Grid>
                                            </Grid>
                                        ))}
                                    </Grid>
                                ) : (
                                    <Alert severity="error"  className='business-alert'>
                                        No frozen clients found.
                                    </Alert>
                                )}
                            </Box>
                        </>
                    ) : (
                        <Alert severity="error"  className='business-alert'>
                            Unable to retrieve client data. Please try refreshing the page or contact support for help.
                        </Alert>
                    )}

                    <Grid container justifyContent="flex-end" mt={4}>
                        <Grid item>
                            <Button 
                                className='save-business-btn' 
                                type="submit" 
                                disabled={isSubmitLoading}
                                onClick={handleSubmit}
                            >
                                {isSubmitLoading ? (
                                    <>
                                        Submit&nbsp;
                                        <CircularProgress size={16} color="inherit" />
                                    </>
                                ) : (
                                    'Submit'
                                )}
                            </Button>
                        </Grid>
                    </Grid>
                </>
            )}
        </Box>
    )
}

export default GeneralSettings;