import React, {useEffect, useState} from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Button from '@mui/material/Button';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import Link from '@mui/material/Link';

import { useYearsFilter } from '../../../../hooks/useYearsFilter';
import { useFetchData } from '../../../../hooks/useFetchData';

import LoadingGrid from '../../../ui/Loading';
import ClientQueView from "./ClientQueView";
import ClientQueEdit from "./ClientQueEdit";
import EmptyDataComponent from '../../../ui/EmptyDataComponent';

import QueIcon from '../../../../assets/icons/clipboard.png';


const ClientQuestionnaires = () => {

    const { userToken } = useSelector((state) => state.user);
    const { clientId } = useParams();
    const location = useLocation();
    const currentPath = location.pathname;
    const endpoint = currentPath.startsWith('/admin/merge/client-page/')
    ? `/api/client-page/questionnaire/${clientId}?userType=2`
    : `/api/client-page/questionnaire/${clientId}?userType=1`;
    const [loading, data, error] = useFetchData(endpoint, userToken);
    const {
        years,
        selectedYear,
        handleSelectChange,
        filteredList,
    } = useYearsFilter(data);
    const [currentQue, setCurrentQue] = useState(null);
    const [isViewOpen, setViewOpen] = useState(false);
    const [isEditOpen, setEditOpen] = useState(false);


    const handleViewOpen = (item) => {
        setCurrentQue(item);
        setViewOpen(true);
    }

    const handleEditOpen = (item) => {
        setCurrentQue(item);
        setEditOpen(true);
    }

    return(
        <>
            <Box id="questionnaires-admin-wrapper">
                {/* <Grid container>
                    <Grid item xs={12} mb={3}>
                        <Typography component="h1">
                            Questionnaires
                        </Typography>
                    </Grid>
                </Grid> */}
                <Grid container mb={2}>
                    <Grid item>
                        <Link 
                            style={{ textDecoration: 'none', display: 'flex' }} 
                            href={`/admin/client-page/documents/${clientId}`}
                            className='files-go-back-btn'
                        >
                            <ChevronLeftIcon />
                            Go Back
                        </Link>
                    </Grid>
                </Grid>
                <Grid container className="top-section" justifyContent="space-between" alignItems="center" mb={4}>
                    <Grid item className="breadcrumb-container" xs={6}>
                        <Breadcrumbs>
                            <Typography className='breadcrumb-main'>All Documents</Typography>
                            <Typography>questionnaires</Typography>
                        </Breadcrumbs>
                    </Grid>
                    <Grid item className="year-select-container">
                        <FormControl fullWidth variant="outlined" sx={{width: '150px'}}>
                            <InputLabel id="year-select-label">Year</InputLabel>
                            <Select
                                labelId="year-select-label"
                                value={selectedYear}
                                onChange={handleSelectChange}
                                label="Year"
                            >
                                {years.map(year => (
                                    <MenuItem key={year} value={year}>
                                        {year}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>
                <Box>
                    {loading && <LoadingGrid />}
                    {error && (
                        <Typography component="h2" variant="h2">
                            An error occurred.
                        </Typography>
                    )}
                    {data && data.length === 0 && (
                        <EmptyDataComponent msg="No questionnaires found. All questionnaires will be displayed here." />
                    )}
                    {data && data.length > 0 && (
                        <>
                            {/* <Grid container justifyContent="flex-end">
                                <Grid item xs={2}>
                                    <FormControl fullWidth>
                                        <InputLabel id="demo-simple-select-label">Year</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                value={selectedYear}
                                                label="Year"
                                                onChange={handleSelectChange}
                                                size='small'
                                            >
                                                {years ? (
                                                    years
                                                    .sort((a, b) => a + b)
                                                    .map((item, i) => (
                                                        <MenuItem key={i} value={item}>{item}</MenuItem>
                                                    ))
                                                ) : null}
                                            </Select>
                                    </FormControl>
                                </Grid>
                            </Grid> */}
                            <Grid container columnGap={3} rowGap={2} mt={3}>
                                {filteredList.map((item, i) => (
                                    <Grid
                                        item
                                        key={i}
                                        className="questionnaire-item"
                                        xs={3}
                                        // onClick={() => setActive(item, i)}
                                    >
                                        <Box>
                                            <img src={QueIcon} />

                                        </Box>
                                        <Box className='content-box'>
                                            <Typography>
                                                {`Tax Questionnaire ${item.taxYear}`}
                                            </Typography>
                                            <Box className="buttons-wrapper">
                                                <Button className="view-btn" onClick={() => handleViewOpen(item)}>
                                                    View
                                                </Button>
                                                <Button className="edit-btn" onClick={() => handleEditOpen(item)} >
                                                    Edit
                                                </Button>

                                            </Box>
                                        </Box>   
                                    </Grid>
                                ))}
                            </Grid>
                        </>
                    )}
                </Box>
                <ClientQueView 
                    open={isViewOpen}
                    setOpen={setViewOpen}
                    currentQue={currentQue}
                />
                <ClientQueEdit 
                    open={isEditOpen}
                    setOpen={setEditOpen}
                    currentQue={currentQue}
                    setCurrentQue={setCurrentQue}
                    id={clientId}
                    userToken={userToken}
                />
            </Box>
        </>

    )
}

export default ClientQuestionnaires;