import React from 'react';
import { useParams } from 'react-router-dom';
import GeneralClientFiles from '../../../general-client-components/general-client-documents/general-client-files.js';



const MergedFiles = () => {

    const { mergedClientId } = useParams();

    return (
        <GeneralClientFiles 
            endpoint={`/api/c/dashboard/merged/files/${mergedClientId}`} 
            backLink={`/dashboard/merge/documents/${mergedClientId}`} 
            title="Personal Documents"
        />
    )

}

export default MergedFiles;