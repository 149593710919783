import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Collapse from '@mui/material/Collapse';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import Tooltip from '@mui/material/Tooltip';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

import { useFetchData } from '../../../../hooks/useFetchData';

import PersonalClientInfo from './Personal';
import MyBusiness from './MyBusiness';

import GeneralClientNotifications from '../../../general-client-components/general-notifications';

const ClientInfo = ({ setChildChanges }) => {

    const { userToken, userInfo } = useSelector((state) => state.user);
    const [loading, data, error] = useFetchData('/api/c/dashboard/my-account', userToken);
    const [personalInfoOpen, setPersonalInfoOpen] = useState(true);
    const [businessTitlesOpen, setBusinessTitlesOpen] = useState(true);
    const [notificationsOpen, setNotificationsOpen] = useState(true);
    const [businesses, setBusinesses] = useState(data?.businesses || []);

    useEffect(() => {
        if (data) {
            setBusinesses(data.businesses || []);
        }
    }, [data]);

    const toggleSection = (section) => {
        switch (section) {
            case 'personalInfo':
                setPersonalInfoOpen(!personalInfoOpen);
                break;
            case 'businessTitles':
                setBusinessTitlesOpen(!businessTitlesOpen);
                break;
            case 'notificationsTitles':
                setNotificationsOpen(!notificationsOpen);
            break;
            default:
            break;
        }
    };

    const capitalize = (str) => {
        str = str.replace(/([a-z])([A-Z])/g, '$1 $2');
        return str.charAt(0).toUpperCase() + str.slice(1);
    }

    return (
        <>
            <Box id='my-account-info'>
                <Grid container justifyContent="space-between" alignItems="center">
                    <Grid item>
                        <Typography component="h1">
                            My Dashboard
                        </Typography>
                    </Grid>
                    {!!data && data?.mergedAccount === 2 && (
                        <Grid item className='inactive-account-msg' mt={1}>
                                <Typography component="span">
                                    Inactive account
                                </Typography>
                                <Tooltip title="Inactive Account: Active Merged Account. You can still use this account if you have businesses.">
                                    <HelpOutlineIcon />
                                </Tooltip>
                        </Grid>
                    )}
                </Grid>
                {!!data ? (
                    <>
                        <Box  className={!personalInfoOpen ? 'section' : 'section open'} onClick={() => toggleSection('personalInfo')} mt={5}>
                            <Box display="flex" alignItems="center" justifyContent="space-between" className="section-header">
                                <Typography component="h3">Personal Information</Typography>
                                {!personalInfoOpen ? <ExpandMoreIcon /> : <ExpandLessIcon />}
                            </Box>
                            <Collapse in={personalInfoOpen} timeout={500} unmountOnExit>
                                <PersonalClientInfo clientInfo={data} />
                            </Collapse>
                        </Box>
                        <Box className={!businessTitlesOpen ? 'section' : 'section open'} onClick={() => toggleSection('businessTitles')}>
                            <Box display="flex" alignItems="center" justifyContent="space-between" className="section-header">
                                <Typography component="h3">Businesses</Typography>
                                {!businessTitlesOpen ? <ExpandMoreIcon /> : <ExpandLessIcon />}
                            </Box>
                            <Collapse in={businessTitlesOpen} timeout={500} unmountOnExit>
                                <MyBusiness 
                                    businesses={businesses}
                                    userToken={userToken} 
                                    setBusinesses={setBusinesses}
                                    setChildChanges={setChildChanges}
                                />
                            </Collapse>
                        </Box>
                        <Box className={!notificationsOpen ? 'section' : 'section open'} onClick={() => toggleSection('notificationsTitles')}>
                            <Box display="flex" alignItems="center" justifyContent="space-between" className="section-header">
                                <Typography component="h3">Notifications</Typography>
                                {!notificationsOpen ? <ExpandMoreIcon /> : <ExpandLessIcon />}
                            </Box>
                            <Collapse in={notificationsOpen} timeout={500} unmountOnExit>
                                <GeneralClientNotifications 
                                    userId={userInfo.id}
                                    userToken={userToken} 
                                    userType="personal"
                                />
                            </Collapse>
                        </Box>
                    </>
                ): null}
            </Box>
        </>
    )

}

export default ClientInfo;