import React, { useEffect, useState, useMemo } from 'react';
import { useSelector } from "react-redux";
import { useLocation, useParams} from 'react-router-dom';
import axios from 'axios';
import download from 'downloadjs';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import List from "@mui/material/List";
import ListItem from '@mui/material/ListItem';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import Alert from '@mui/material/Alert';

import PaginationControlled from '../../../ui/Pagination';
import ErrorDataComponent from '../../../ui/ErrorDataComponent';
import LoadingList from '../../../ui/LoadingList';

import { useFetchData } from '../../../../hooks/useFetchData';

import { fileIcons, getFileExtension } from '../../../../utils/fileIcons.js';

import EditFileInfo from '../GeneralDoc/EditFileInfo';
import DeleteFile from '../GeneralDoc/DeleteFile';
import EditMultipleFiles from '../GeneralDoc/EditMultipleFiles';
import CopyFiles from '../GeneralDoc/CopyFiles';




const MergedClientDocuments = () => {

    const { userToken } = useSelector((state) => state.user);
    const { clientId, folderName, year } = useParams();
    const [childChanges, setChildChanges] = useState(false);

    // Calculate the default year (current year - 1)
    const defaultYear = year ? year : (new Date().getFullYear() - 1).toString();

    const [selectedYear, setSelectedYear] = useState(defaultYear);
    const [files, setFiles] = useState([]);
    const [years, setYears] = useState([defaultYear]);
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [selectedFileNames, setSelectedFileNames] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [recordsPerPage] = useState(15);

    const [currentFile, setCurrentFile] = useState(null);
    const [downloadError, setDownloadError] = useState(null)
    const [isEditMode, setEditMode] = useState(false);
    const [isDeleteMode, setDeleteMode] = useState(false);
    const [isEditModeForMultipleFiles, setEditModeForMultipleFiles] = useState(false);
    const [isCopyMode, setCopyMode] = useState(false)

    const [loading, data, error] = useFetchData(`/api/client-page/merged/files/${clientId}/${folderName}/${selectedYear}`, userToken, childChanges);

    useEffect(() => {
        if (data) {
            setFiles(data.files);
            setYears(prevYears => Array.from(new Set([...prevYears, ...data.years])));
        }
    }, [data]);


    // Pagination logic
    const indexOfLastRecord = currentPage * recordsPerPage;
    const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
    const currentRecords = files.slice(indexOfFirstRecord, indexOfLastRecord);
    const nPages = Math.ceil(files.length / recordsPerPage);

    const handleSelectChange = (e) => {
        setSelectedYear(e.target.value);
        setCurrentPage(1); // Reset to first page when changing the year
    };

    const handleEditOpen = (item) => {
        setEditMode(true);
        setCurrentFile(item);
    }

    const handleDeleteOpen = (item) => {
		setCurrentFile(item);
    	setDeleteMode(true)
 	}

    const handleEditOpenForMultipleFiles = (item) => {
        setEditModeForMultipleFiles(true);
    }

    const handleCopyOpen = () => {
        setCopyMode(true)
    }

    const handleCheckboxChange = (fileId, fileName, taxYear) => {
        setSelectedFiles(prevSelectedFiles =>
            prevSelectedFiles.includes(fileId)
                ? prevSelectedFiles.filter(id => id !== fileId)
                : [...prevSelectedFiles, fileId]
        );

        setSelectedFileNames(prevSelectedFileNames => {
            const fileIndex = prevSelectedFileNames.findIndex(file => file.file_name === fileName);
    
            if (fileIndex > -1) {
                // Remove the file object if it already exists
                return prevSelectedFileNames.filter(file => file.file_name !== fileName);
            } else {
                // Add a new file object
                const newFile = { file_name: fileName, taxYear: taxYear };
                return [...prevSelectedFileNames, newFile];
            }
        });
    };

    const handleSelectAll = () => {
        if (selectedFiles.length === data?.files.length.length) {
            setSelectedFiles([]); 
            setSelectedFileNames([]); 
        } else {
            setSelectedFiles(data?.files.map(item => item._id)); 
            setSelectedFileNames(data?.files.map(item => {
                return {
                    file_name: item.file_name, 
                    taxYear: item.taxYear
                };
            }));
        }
    };

    const downloadFile = async (id, filename, mimetype) => {

        const config = {
            responseType: 'blob',
            headers: {
                Authorization: `Bearer ${userToken}`,
            },
        };
        
        try {
            const response = await axios.get(`/api/client-page/file/download/${id}`, config);
            const file = new Blob([response.data], { type: mimetype });
      
            if (file.size === 0) {
                setDownloadError('The file could not be downloaded because it is empty. Please contact support for assistance.');
                return;
            }

            const realFileName = filename;
      
            download(file, realFileName, mimetype);
        } catch (error) {
            console.log(error)
            if (error.response && error.response.status === 400) {
                setDownloadError('An error occurred while downloading the file. Please try again later or contact support if the issue persists.');
                return;
            }
            setDownloadError('Unable to download the file. Please check your internet connection and try again.');
        }
        
    };


    return (
        <>
            <Box id="admin-folders-files-container">
                <Grid container mb={2}>
                    <Grid item>
                        <Link 
                            style={{ textDecoration: 'none', display: 'flex' }} 
                            href={`/admin/merge/client-page/documents/${clientId}`}
                            className='files-go-back-btn'
                        >
                            <ChevronLeftIcon />
                            Go Back
                        </Link>
                    </Grid>
                </Grid>
                <Grid container className="top-section" justifyContent="space-between" alignItems="center">
                    <Grid item className="breadcrumb-container" xs={6}>
                        <Breadcrumbs>
                            <Typography className='breadcrumb-main'>All Documents</Typography>
                            <Typography>{folderName}</Typography>
                        </Breadcrumbs>
                    </Grid>
                    <Grid item className="year-select-container">
                        <FormControl fullWidth variant="outlined" sx={{width: '150px'}}>
                            <InputLabel id="year-select-label">Year</InputLabel>
                            <Select
                                labelId="year-select-label"
                                value={selectedYear}
                                onChange={handleSelectChange}
                                label="Year"
                            >
                                {years.map(year => (
                                    <MenuItem key={year} value={year}>
                                        {year}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>
                {loading && (
                    <LoadingList />
                )}
                {downloadError && (
                    <Alert severity="error" sx={{width: '90%', mt: 2}} onClose={() => setDownloadError(null)}  className='business-alert'>
                    {downloadError}
                    </Alert>
                )}
                {data && data?.files.length > 0 ? (
                    <>
                        <Grid container className='multiple-edit-btn-wrapper'>
                            <Grid item>
                                <Button
                                    onClick={handleSelectAll}
                                    className='select-all-btn'
                                >
                                    Select All
                                </Button>
                            </Grid>
                            {selectedFiles.length > 0 && (
                                <>
                                    <Grid item>
                                        <Button
                                            className='multiple-edit-btn'
                                            onClick={handleEditOpenForMultipleFiles}
                                        >
                                            Edit
                                        </Button>
                                    </Grid>
                                    {/* <Grid item>
                                        <Button
                                            className='multiple-copy-btn'
                                            onClick={handleCopyOpen}
                                        >
                                            Copy
                                        </Button>
                                    </Grid> */}
                                </>
                            )}
                        </Grid>
                        <Grid container sx={{width: "100%"}}>
                            <Grid item xs={12}>
                                <List className="file-list">
                                {currentRecords && currentRecords.map((item, i) => (
                                        <ListItem key={i} className="file-list-item">
                                            <Checkbox
                                                checked={selectedFiles.includes(item._id)}
                                                onChange={() => handleCheckboxChange(item._id, item.file_name, item.taxYear)}
                                            />
                                            <a href={item.file_url} target="_blank" rel="noopener noreferrer">
                                                <>
                                                    <img src={fileIcons[getFileExtension(item.file_name)] || fileIcons.default} alt="file icon" className='file-icon' />
                                                    {item.file_name}
                                                </>
                                            </a>
                                            <ListItemSecondaryAction>
                                                <IconButton 
                                                    onClick={() => 
                                                    downloadFile(item._id, item.file_name, item.file_mimetype)}
                                                >
                                                    <FileDownloadIcon />
                                                </IconButton>
                                                <IconButton onClick={() => handleEditOpen(item)}>
                                                    <EditIcon />
                                                </IconButton>
                                                <IconButton onClick={() => handleDeleteOpen(item)}>
                                                    <DeleteIcon />
                                                </IconButton>
                                            </ListItemSecondaryAction>
                                        </ListItem>
                                    ))}

                                </List>
                            </Grid>

                        </Grid>
                        <Grid container justifyContent="center" mt={4} mb={4}>
                            <Grid item>
                                <PaginationControlled
                                    nPages={nPages}
                                    currentPage={currentPage}
                                    setCurrentPage={setCurrentPage} 
                                />
                            </Grid>
                        </Grid>
                        <EditFileInfo 
                            open={isEditMode} 
                            setOpen={setEditMode} 
                            currentFile={currentFile} 
                            businesses={data.userBusinesses}
                            userToken={userToken}
                            folders={data.allFolders}
                            userId={clientId}
                            setChildChanges={setChildChanges}
                        />
                         <DeleteFile 
                            open={isDeleteMode}
                            setOpen={setDeleteMode}
                            currentFile={currentFile} 
                            userToken={userToken}
                            setChildChanges={setChildChanges}
                        />
                        <EditMultipleFiles 
                            open={isEditModeForMultipleFiles}
                            setOpen={setEditModeForMultipleFiles}
                            folders={data.allFolders}
                            businesses={data.userBusinesses}
                            userToken={userToken}
                            selectedFiles={selectedFiles}
                            setSelectedFiles={setSelectedFiles}
                            currentFolder={data.currentFolder}
                            userId={clientId}
                            setChildChanges={setChildChanges}
                            selectedYear={selectedYear}
                        />
                        {/* <CopyFiles 
                            open={isCopyMode}
                            setOpen={setCopyMode}
                            selectedFiles={selectedFiles}
                            setSelectedFiles={setSelectedFiles}
                            selectedFileNames={selectedFileNames}
                            setSelectedFileNames={setSelectedFileNames}
                            setChildChanges={setChildChanges}
                        /> */}
                    </>
                ) : (
                    <ErrorDataComponent msg={error?.data?.error || 'Something went wrong. Please reload the page or contact support if the issue persists.'} />
                )}
               

            </Box>
        </>
    )
}

export default MergedClientDocuments;