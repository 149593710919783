import React, {useEffect, useState} from 'react';
import { useSelector } from 'react-redux';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';


import MergeAccounts from './MergeAccounts';
import SplitAccounts from './SplitAccounts';
import BusinessAccountIntegration from './BusinessMergeAcc';

const AccountIntegration = () => {

    const { userToken } = useSelector((state) => state.user);
    const [value, setValue] = useState('1');
    const [searchQue, setSearchQue] = useState('');

    const handleChange = (event, newValue) => {
        setValue(newValue);
        setSearchQue('')
    };

    const handleSearchChange = e => {
        setSearchQue(e.target.value);
  };

    return (
        <>
            <Box className='merge-account-wrapper' id="merge-account-wrapper">
                <Grid container mb={4}>
                    <Grid item>
                        <Typography component='h1'>
                            Account Integration
                        </Typography>
                    </Grid>
                </Grid>
                <Box>
                    <TabContext value={value}>
                        <Box sx={{ width: '100%' }} className={value !== '1' ? 'search-present' : 'no-search'}>
                            {value !== '1' && (
                                <Box className='merge-search'>
                                    <TextField 
                                        id="outlined-basic"
                                        label="Search"
                                        placeholder={value === '2' ? 'Search by client name' : 'Search by business name'}
                                        size="small" 
                                        variant="outlined"
                                        fullWidth
                                        name="search-client"
                                        value={searchQue}
                                        InputProps={{ 
                                            className: 'field',
                                        }}
                                        onChange={handleSearchChange} 
                                        className="search-bar"
                                    />
                                </Box>

                            )}
                            
                            <TabList
                                onChange={handleChange}
                                textColor="secondary"
                                indicatorColor="secondary"
                                className="styled-tablist"
                            
                            >
                                <Tab value="1" className='first-tab styled-tab' label="Merge Accounts"/>
                                <Tab value="2" className='second-tab styled-tab' label="Split Accounts"/>
                                <Tab value="3" className='third-tab styled-tab' label="Businesses"/>
                            </TabList>
                        </Box>
                        <TabPanel value="1">
                            <MergeAccounts userToken={userToken} />
                        </TabPanel>
                        <TabPanel value="2">
                            <SplitAccounts userToken={userToken} searchQue={searchQue} />
                        </TabPanel>
                        <TabPanel value="3">
                            <BusinessAccountIntegration userToken={userToken} searchQue={searchQue} />
                        </TabPanel>
                    </TabContext>
                </Box> 
            </Box>
        </>

    )

}

export default AccountIntegration;