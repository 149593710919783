import React from 'react';
import GeneralClientFiles from '../../../general-client-components/general-client-documents/general-client-files.js';



const PersonalFiles = () => {

    return (
        <GeneralClientFiles 
            endpoint="/api/c/dashboard/personal/files" 
            backLink="/dashboard/documents/personal" 
            title="Personal Documents"
        />
    )

}

export default PersonalFiles;