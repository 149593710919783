import React, { useEffect, useState, useRef } from 'react';
import { useDispatch } from 'react-redux';
import axios from 'axios';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import Checkbox from '@mui/material/Checkbox';
import FormLabel from '@mui/material/FormLabel';
import IconButton from '@mui/material/IconButton';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import Button from '@mui/material/Button';
import Switch from '@mui/material/Switch';
import Alert from '@mui/material/Alert';
import DeleteIcon from '@mui/icons-material/Delete';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import CircularProgress from '@mui/material/CircularProgress';
import ReplayIcon from '@mui/icons-material/Replay';

import NewUsers from './NewUsers';
import DialogSuccessMsg from '../../../../ui/DialogSuccessMsg';

import { fetchAdminClientDetails } from '../../../../../actions/adminClientActions';


const ActiveAndNewUsers = ({  activeCurrentUsers, userToken, businessId, setChildChanges, view, id, setSubmitted, isSubmitted }) => {

    const dispatch = useDispatch();
    const [users, setUsers] = useState([]);
    const [activeUsers, setActiveUsers] = useState([]);
    const [frozenUsers, setFrozenUsers] = useState([]);
    const [newUsers, setNewUsers] = useState([{
        user: null,
        permissions: 'view',
        firstName: '',
        lastName: ''
    }]);
    const [error, setError] = useState(null); 
	const [loading, setLoading] = useState(false);
    // const [isSubmitted, setSubmitted] = useState(false);
    const [isEditingActiveUsers, setEditingActiveUsers] = useState(true); // Indicates if the form for editing active users is active
    const [isAddingNewUsers, setAddingNewUsers] = useState(false); // Indicates if the form for adding new users is active


    const correctPassword = "12345";

    useEffect(() => {
        if (activeCurrentUsers) {
            // Initialize users with a `frozen` property to manage freezing without changing order
            const initializedUsers = activeCurrentUsers.map(user => ({
                ...user,
            }));
            setUsers(initializedUsers);
            setActiveUsers(initializedUsers.filter(user => user.active));

            // Set all password inputs to an empty string
            const passwordFields = document.querySelectorAll('input[name="password"]');
            passwordFields.forEach(field => {
                field.value = '';
            });

            setNewUsers([{
                user: null,
                permissions: 'view',
                firstName: '',
                lastName: ''
            }])

            setError(null)
        }
    }, [activeCurrentUsers, isEditingActiveUsers]);

    useEffect(() => {
        if(isSubmitted && id && view === 'account') {
            dispatch(fetchAdminClientDetails({ clientId: id, userType: 1 }));
        }
    }, [isSubmitted])


    // useEffect(() => {
    //     if(frozenUsers) {
    //         console.log('Frozen', frozenUsers)
    //     }
    // }, [frozenUsers])

    // useEffect(() => {
    //     if(activeCurrentUsers) {
    //         console.log('New', activeCurrentUsers)
    //     }
    // }, [activeCurrentUsers])

    // useEffect(() => {
    //     if(view === 'general' && isSubmitted) {
    //         console.log('here')
    //         setChildChanges((prevChanges) => !prevChanges);
    //     }
    //     if(isSubmitted && id && view === 'account') {
    //         dispatch(fetchAdminClientDetails({ clientId: id, userType: 1 }));
    //     }
    // }, [isSubmitted])

    // Function to make ACTIVE or NEW users editable 

    const handleEditUsers = (e) => {
        setEditingActiveUsers(!isEditingActiveUsers)
        setAddingNewUsers(!isAddingNewUsers)
    }

    // Function to handle password input and freeze logic
    const handlePasswordChange = (e, userIndex) => {
        const passwordValue = e.target.value;
    
        setUsers(prevUsers => {
            const updatedUsers = [...prevUsers];
    
            // Store the original state of the user before freezing
            const originalPermissions = updatedUsers[userIndex].originalPermissions || updatedUsers[userIndex].permissions;
            const originalActiveState = updatedUsers[userIndex].originalActiveState !== undefined 
                ? updatedUsers[userIndex].originalActiveState 
                : updatedUsers[userIndex].active;
    
            if (passwordValue === correctPassword) {
                // Freeze the user (set active to false) and save the original state
                updatedUsers[userIndex] = {
                    ...updatedUsers[userIndex],
                    active: false,
                    permissions: updatedUsers[userIndex].permissions === 'none' ? 'none' : 'view',
                    originalPermissions, // Save original permissions
                    originalActiveState, // Save original active state
                };
            } else {
                // If password is cleared or incorrect, restore the user's original state
                updatedUsers[userIndex] = {
                    ...updatedUsers[userIndex],
                    active: originalActiveState,
                    permissions: originalPermissions,
                    originalPermissions: undefined, // Reset original state
                    originalActiveState: undefined, // Reset original state
                };
            }
    
            // Update activeUsers and frozenUsers arrays
            setActiveUsers(updatedUsers.filter(user => user.active));
            setFrozenUsers(updatedUsers.filter(user => !user.active));
    
            return updatedUsers;
        });
    };
    
    const handlePermissionChange = (e, userIndex, permissionType) => {
        const isChecked = e.target.checked;

        setUsers(prevUsers => {
            const updatedUsers = prevUsers.map((user, index) => {
                if (index === userIndex) {
                    // Active users cannot uncheck 'view'
                    if (user.active && permissionType === 'view' && !isChecked) {
                        return user;
                    }

                    const updatedPermissions = isChecked
                        ? permissionType
                        : permissionType === 'upload'
                        ? 'view'
                        : 'none'; // Frozen users can set to 'none' by unchecking 'view'

                    return { ...user, permissions: updatedPermissions };
                }
                return user;
            });

            // Update activeUsers and frozenUsers arrays based on the updated permissions
            setActiveUsers(updatedUsers.filter(user => user.active));
            setFrozenUsers(updatedUsers.filter(user => !user.active));

            return updatedUsers;
        });
    };

    const hasAtLeastOneUpload = () => {
        return activeUsers.length > 0 && activeUsers.some(user => user.permissions === 'upload');
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        if (!hasAtLeastOneUpload()) {
            setError("Business must have at least one user with 'upload' permission.");
            return;
        }
        setLoading(true);
        try {
            const config = {
                headers: {
                    Authorization: `Bearer ${userToken}`,
                },
            };

            const data = {
                activeUsers: activeUsers,
                frozenUsers: frozenUsers,
            }

            const response = await axios.put(
                `/api/admin/businesses/${businessId}/settings/edit-users`,
                data,
                config
            );
            if (response.data.error) {
                setError(response.data.error);
            } else {
                setSubmitted(true);
                setError('');
            }
        } catch (error) {
            console.log(error)
            setError('Error')
        } finally {
            setLoading(false);
        }
    };

    const handleReload = (e) => {
        e.stopPropagation();
        setChildChanges((prevChanges) => !prevChanges);
        setSubmitted(false);
        setEditingActiveUsers(true);
        setAddingNewUsers(false);
    }

    if (isSubmitted) {
        return (
            <Box>
                <DialogSuccessMsg msg="Business information updated successfully." />
                <Grid container justifyContent="center">
                    <Grid item>
                        <Button 
                            variant="outlined" 
                            startIcon={<ReplayIcon />}
                            onClick={handleReload}
                        >
                            Reload
                        </Button>
                    </Grid>
                </Grid>
            </Box>
        )
    }



    return (
        <Box>
            {users && users.length > 0 ? (
                <Box className='active-users' mb={5}>
                    <Grid container className='heading-container' justifyContent="space-between" alignItems="center">
                        <Grid item>
                            <Typography className='heading' component="p">Active clients</Typography>
                        </Grid>
                        <Grid item>
                            {isEditingActiveUsers ? (
                                <Typography className='editing'>
                                    editing
                                </Typography>
                            ) : (
                                <IconButton
                                    onClick={handleEditUsers}
                                    className='edit-btn'
                                >
                                    <BorderColorIcon />
                                </IconButton>
                            )}
                        </Grid>
                    </Grid>
                    {error && (
                         <Alert severity="error"  onClose={() => setError(null)} className='business-alert'>
                            {error}
                         </Alert>
                    )}
                    <Grid container spacing={2}>
                        {users.map((user, index) => (
                            <Grid item container xs={12} alignItems="center" spacing={2} key={user.user} >
                                <Grid item flex="1">
                                    <Typography>{user.firstName} {user.lastName}</Typography>
                                </Grid>
                                <Grid item>
                                    <FormControlLabel
                                        control={
                                            <Checkbox 
                                            checked={user.permissions === 'view' || user.permissions === 'upload'}
                                            onChange={(e) => handlePermissionChange(e, index, 'view')}
                                            disabled={user.active || !isEditingActiveUsers}
                                            />
                                        }
                                        label="View"
                                    />
                                </Grid>
                                <Grid item>
                                    <FormControlLabel
                                        control={
                                            <Checkbox 
                                                checked={user.permissions === 'upload'}
                                                onChange={(e) => handlePermissionChange(e, index, 'upload')}
                                                disabled={!user.active || !isEditingActiveUsers} // Disable "upload" if the user is frozen (active: false)  
                                            />
                                        }
                                    label="Upload"
                                    />
                                </Grid>
                                <Grid item>
                                    <TextField 
                                        size='small'
                                        label="Password" 
                                        onChange={(e) => handlePasswordChange(e, index)}
                                        disabled={!isEditingActiveUsers}
                                        name="password"
                                    />
                                </Grid>
                                <Grid item>
                                    <FormLabel>Freeze</FormLabel>
                                    <Switch
                                        name="freeze"
                                        disabled={true}
                                        checked={!user.active}
                                    />
                                </Grid>

                            </Grid>
                        ))}
                        {isEditingActiveUsers && (
                            <Grid item xs={12} container justifyContent="flex-end">
                                <Grid item>
                                    <Button 
                                        className='save-business-btn' 
                                        type="submit" 
                                        disabled={loading || !isEditingActiveUsers}
                                        onClick={handleSubmit}
                                    >
                                        {loading ? (
                                            <>
                                                Submit&nbsp;
                                                <CircularProgress size={16} color="inherit" />
                                            </>
                                        ) : (
                                            'Submit'
                                        )}
                                    </Button>
                                </Grid>
                            </Grid>
                        )}
                    </Grid>
                </Box>
            ) : null}

            <Box mt={2} className='active-users'>
                <Grid container className='heading-container' justifyContent="space-between" alignItems="center">
                    <Grid item>
                        <Typography className='heading' component="p">New Clients</Typography>
                    </Grid>
                    <Grid item>
                        {isAddingNewUsers ? (
                             <Typography className='editing'>
                                editing
                            </Typography>
                                
                        ) : (
                            <IconButton
                                onClick={handleEditUsers}
                                className='edit-btn'
                            >
                                    <BorderColorIcon />
                            </IconButton>
                        )}
                    </Grid>
                </Grid>
               
                {isAddingNewUsers ? (
                    activeCurrentUsers.length <= 2 ? (
                        <NewUsers 
                            userToken={userToken} 
                            businessId={businessId}
                            setNewUsers={setNewUsers}
                            newUsers={newUsers}
                            activeUsers={activeUsers}
                            isEditingActiveUsers={isEditingActiveUsers}
                            setSubmitted={setSubmitted}
                        />
                    ) : (
                        <Typography className="new-clients-msg">
                            You have reached the maximum number of clients that can be associated with this business.
                        </Typography>
                        )
                ) : (
                    <Typography className="new-clients-msg">
                        You are currently managing active users.
                    </Typography>
                )}
            </Box>
            

        </Box>
    )
}


export default ActiveAndNewUsers;