import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Menu from '@mui/material/Menu';
import Stack from '@mui/material/Stack';
import MenuItem from '@mui/material/MenuItem';
import AddIcon from '@mui/icons-material/Add';
import Typography from '@mui/material/Typography';
import Chip from '@mui/material/Chip';
import SettingsIcon from '@mui/icons-material/Settings';
import VisibilityIcon from '@mui/icons-material/Visibility';
import DeleteIcon from '@mui/icons-material/Delete';

import AddBusiness from './AddBusiness';
import BusinessDialog from './BusinessDialog';
import BusinessSettingsModal from '../../../MergeAcc/BusinessMergeAcc/BusinessSettingsModal';
import BusinessSettingsModal2 from '../../../MergeAcc/BusinessMergeAcc/BusinessSettingsModal2';

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(2),
    color: theme.palette.text.secondary,
}));

const BusinessInfo = ({ userToken, id, businesses, setBusinesses, userType, userId, setChildChanges }) => {

    const [isAddNewOpen, setIsAddNewOpen] = useState(false);
    const [isSettingsOpen, setSettingsOpen] = useState(false);
    const [openBusinessMenu, setOpenBusinessMenu] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [currentBusiness, setCurrentBusiness] = useState(null);
    const [dialog, setDialog] = useState(null);
    const [businessId, setBusinessId] = useState('')

    useEffect(() => {
        if(currentBusiness) {
            setBusinessId(currentBusiness._id)
        }
    }, [currentBusiness])

    // useEffect(() => {
    //     if(businesses) {
    //         console.log(businesses)
    //     }
    // }, [businesses])
  
    const handleBusinessMenuOpen = (event, item) => {
        event.stopPropagation();
        setAnchorEl(event.currentTarget);
        setCurrentBusiness(item);
        setOpenBusinessMenu(true);
    };
  
    const handleBusinessMenuClose = (event) => {
        setAnchorEl(null);
        setOpenBusinessMenu(false);
    };

    const handleAddNewOpen = (event) => {
        event.stopPropagation();
        setIsAddNewOpen(true);
    }

    const handleSettingsOpen = (event) => {
        event.stopPropagation();
        setSettingsOpen(true);
        setOpenBusinessMenu(false);
    }

    const handleDelete = (event) => {
        event.stopPropagation();
        setDialog('delete');
        setOpenBusinessMenu(false);
    };
    
    const handleView = (event) => {
        event.stopPropagation();
        setDialog('view');
        setOpenBusinessMenu(false);
    };
    
    const handleDialogClose = (event) => {
        event.stopPropagation();
        setDialog(null);
    }

    const updateBusiness = (updatedBusiness) => {
        setBusinesses((prevBusinesses) => {
          return prevBusinesses.map((business) => {
            if (business._id === updatedBusiness._id) {
              return updatedBusiness; // return updated business if the ID matches
            }
            return business; // return original business otherwise
          });
        });
    };

    const deleteBusiness = (businessId) => {
        setBusinesses((prevBusinesses) => {
          return prevBusinesses.filter((business) => business._id !== businessId);
        });
    };
  
    const BusinessMenu = ({ anchorEl, open, onClose }) => (
        <Menu anchorEl={anchorEl} open={open} onClose={onClose} className='business-menu'>
            <MenuItem onClick={handleView}>
                <VisibilityIcon/>
                View
            </MenuItem>
            <MenuItem onClick={handleSettingsOpen}>
                <SettingsIcon />
                User Settings
            </MenuItem>
            <MenuItem key="delete" onClick={handleDelete}>
                <DeleteIcon />
                Delete
            </MenuItem>
        </Menu>
    );
  
    const renderBusinessGrid = (status) => (
        businesses.map((item, index) => (
            <Grid item xs={12} sm={6} md={5} lg={4} key={index}>
                <Paper className="business-card">
                    <Box className={!item.userStatus ? "business-card-header" : "business-card-header only-icon"}>
                        {!item.userStatus && (
                            <Stack direction="row" spacing={1} className="business-chip-stack">
                                <Chip label="Access Frozen" size="small" className="chip-frozen" />
                                {item.dissolvedDate && (
                                    <Chip label="Dissolved" size="small" className="chip-dissolved" />
                                )}
                            </Stack>
                        )}
                        <IconButton
                            onClick={(e) => {
                                e.stopPropagation();
                                handleBusinessMenuOpen(e, item);
                            }}
                        >
                            <MoreHorizIcon />
                        </IconButton>
                    </Box>
          
                    <Link
                        to={`/admin/client-page/business/documents/${id}?businessId=${item._id}`}
                        className="business-link"
                    >
                        <Box className="business-name-box">
                            <Typography className="business-name-label">Business Name:</Typography>
                            <Typography className="business-name-value">{item.businessName}</Typography>
                        </Box>
                    </Link>
                </Paper>
            </Grid>
        ))
    );
  
    return (
        <Box className="section-content business-info" sx={{ width: '100%' }}>
            <Grid container alignItems="center" rowSpacing={1} columnSpacing={{ xs: 1, sm: 2 }}>
                {renderBusinessGrid(0)}
                <Grid item xs={1} container>
                    <Grid item>
                        <IconButton onClick={handleAddNewOpen}>
                            <AddIcon />
                        </IconButton>
                    </Grid>
                </Grid>
            </Grid>
            {/* {businesses.some(item => item.status === 1) && (
                <Grid container alignItems="center" rowSpacing={1} columnSpacing={{ xs: 1, sm: 2 }}>
                    <Grid item xs={12}>
                        <Typography variant="h6">Archived Businesses</Typography>
                    </Grid>
                    {renderBusinessGrid(1)}
                </Grid>
            )} */}
            <BusinessMenu
                anchorEl={anchorEl}
                open={openBusinessMenu}
                onClose={(e) => { e.stopPropagation(); handleBusinessMenuClose(); setDialog(null); }}
            />
            <AddBusiness
                open={isAddNewOpen} 
                setOpen={setIsAddNewOpen}
                userToken={userToken} 
                id={id} 
            />
            <BusinessDialog 
                dialogType={dialog} 
                business={currentBusiness} 
                onClose={handleDialogClose} 
                userToken={userToken} 
                updateBusiness={updateBusiness}
                deleteBusiness={deleteBusiness}
                id={id} 
            />
            <BusinessSettingsModal2 
                userToken={userToken} 
                open={isSettingsOpen}
                setOpen={setSettingsOpen}
                businessId={businessId}
                view="account"
                id={id} 
            />
        </Box>
    );
};
  
export default BusinessInfo;