import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import ClientProfile from "./ClientProfile";
import ClientQuestionnaires from "./ClientQuestionnaires";
import MergedDocumentsSummary from './MergedDocuments';
import MergedClientDocuments from './MergedDocuments/MergedClientDocuments';
import ClientRefunds from "./ClientRefunds";
import ClientPage from './index';



const AdminMergeClientPageRoutes = () => {

    return (
        <Routes>
            <Route path="/" element={<ClientPage />}>
                <Route index element={<Navigate replace to="client-page/:clientId" />} />
                <Route path="client-page/:clientId" element={<ClientProfile />} />
                <Route path="client-page/documents/:clientId/folder/questionnaire" element={<ClientQuestionnaires />} />
                <Route path="client-page/documents/:clientId" element={<MergedDocumentsSummary />} />
                <Route path="client-page/documents/:clientId/folder/:folderName/year/:year" element={<MergedClientDocuments />} />
                <Route path="client-page/refunds/:clientId" element={<ClientRefunds />} />
            </Route>
        </Routes>
    );
};
  
export default AdminMergeClientPageRoutes;